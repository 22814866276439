<template>
   <div class="container">
      <div class="row">
            <div class="col-lg-12 col-sm-12">
               <div class="iq-privacy-policy">
                  <div class="mb-3">
                     <h4 class="mb-3">{{$t('privacy.section1')}}</h4>
                     <p class="my-3">{{$t('privacy.terms1')}}</p><br>
                  </div>
                  <div class="mb-3">
                     <h4 class="mb-3">{{$t('privacy.section2')}}</h4>
                     <p class="mb-0">{{$t('privacy.terms2')}}</p><br>
                  </div>
                  <div class="mb-3">
                     <h4 class="mb-3">{{$t('privacy.section3')}}</h4>
                     <p class="">{{$t('privacy.terms3')}}</p><br>
                  </div>
                  <div class="mb-3">
                     <h4 class="mb-3">{{$t('privacy.section4')}}</h4>
                     <p class="">{{$t('privacy.terms4')}}</p><br>
                  </div>
                  <div class="mb-0">
                     <h4 class="mb-3">{{$t('privacy.section5')}}</h4>
                     <p class="mb-0">{{$t('privacy.terms5')}}</p>
                  </div>
           </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
  name: 'PrivacyPolicyDetail'
}
</script>
