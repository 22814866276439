<template>
      <Slick id="trending-slider-nav" @afterChange="afterChange" class="list-inline p-0 mb-0 row align-items-center" style="display:block" :option="trendingOption1">
          <li v-for="(item,index) in sliderData" :key="index">
            <a href="javascript:void(0);">
                <div class="movie-slick position-relative">

                  <img :src="item.bannerImageUrl" class="img-fluid" alt="" :style="'max-width:' + (80/maxLength) +'vw'">
                  <div class="block-description" style="margin-top:20px;margin-bottom:20px;">
                    <p style="margin-bottom:0px;margin-top:auto;font-size:12px;">{{(item.title.toUpperCase() + ' - '  + (item.seasons ? item.seasons.length: '')+ ' '+ $t('general.seasons'))}}</p>
                  </div>
                </div>
            </a>
          </li>
      </Slick>
</template>
<script>
export default {
  name: 'TrendingNav',
  methods: {
    afterChange (e, slick, c) {
      this.$emit('afterChange', c)
    }
  },
  data () {
    return {
    }
  },
  props: ['sliderData', 'currentWidth'],
  computed: {
    trendingOption1 () {
      return {
        slidesToShow: this.sliderData.length - 1,
        slidesToScroll: 1,
        dots: false,
        variableWidth: true,
        arrows: false,
        infinite: true,
        centerMode: true,
        centerPadding: 0,
        focusOnSelect: true,
        responsive: [
          {
            breakpoint: 500,
            settings: {
              arrows: true,
              prevArrow: '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
              nextArrow: '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>'
            }
          }
        ]
      }
    },
    maxLength () {
      let layoutLength = 1
      if (this.currentWidth > 400) {
        layoutLength = 1
      }
      if (this.currentWidth > 700) {
        layoutLength = 2
      }
      if (this.currentWidth > 1000) {
        layoutLength = 3
      }
      if (this.currentWidth > 1200) {
        layoutLength = 4
      }
      if (this.currentWidth > 1400) {
        layoutLength = 5
      }
      return layoutLength
    }
  }
}
</script>

<style scoped>
.slick-slide.slick-cloned{
  width:21vw;
}
.slick-track{
  width:90vw!important;
  min-height: 100vh!important;
}
.slider-detail{
  width:90vw!important;
  min-height: 100vh!important;
}
.movie-slick.position-relative img{
  min-height: 150px;
}

</style>
