<template>
  <div style="width:100%;">
    <img :src="detail.bannerAvAdvertisementsUrl"
      class="mobile-adv"
      @click="redirectToUrl(detail.buttonUrl)"/>
    <img :src="detail.backgroundBannerUrl"
      class="full-adv"
      @click="redirectToUrl(detail.buttonUrl)"/>
  </div>
</template>
<script>
export default {
  name: 'Advertisements',
  props: ['detail'],
  components: {
  },
  methods: {
    redirectToUrl (url) {
      window.location.href = url
    }
  }
}
</script>
<style>
  .full-adv {
    display: block;
    cursor:pointer;
    width:100vw!important;
  }
  .mobile-adv {
    display: none;
    cursor:pointer;
    width:100vw!important;
  }
@media (max-width:600px) {
  .full-adv {
    display: none!important;
    width:100vw!important;
  }
  .mobile-adv {
    display: block!important;
    width:100vw!important;
  }
}
</style>
