import axios from './index'

export default {
  async getUserDetails () {
    try {
      const res = await axios.get('/api/Accounts/GetAccountDetails', createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async changePassword (currentPassword, newPassword) {
    try {
      const res = await axios.post('/api/Accounts/ChangePassword', { currentPassword, newPassword }, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async changeDetails (firstName, lastName, birthdate, preferredLanguage, avatar) {
    try {
      const res = await axios.post('/api/Accounts/ChangeAccountDetails', { firstName, lastName, birthdate, preferredLanguage, avatar }, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async GetProfileInfo () {
    try {
      const res = await axios.get('/api/Accounts/GetProfileInfo', createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  }
}

const createConfig = () => {
  const accessToken = localStorage.getItem('token')
  return {
    headers: {
      Authorization: 'Bearer ' + accessToken
    }
  }
}
