// libraries
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import i18n from './i18n'
import './client/plugins'
import auth from './client/services/auth'
import jwtDecode from 'jwt-decode'

// components
import App from './App'
import AuthLayout from './client/page/AuthLayout'
import clientRoutes from './client/publicRoutes'
import NotFound from './client/page/public/NotFound'
import privatePaths from './client/privateRoutes'
import FrontendLayout from './client/page/FrontendLayout'
import './client/directives'

// modules
import userModule from './client/store/user'

// Code
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { userModule: userModule }
})

Vue.use(VueRouter)
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/auth',
      name: 'client',
      component: AuthLayout,
      children: clientRoutes
    },
    {
      path: '/',
      component: {
        render (c) { return c('router-view') }
      },
      redirect: `/${i18n.locale}`
    },
    {
      path: '/:lang',
      component: FrontendLayout,
      children: privatePaths
    },
    {
      path: '/:pathMatch(.*)*',
      component: NotFound
    }
  ]
})

router.beforeEach(async (to, from, next) => {
  let language = to.params.lang
  if (!language) {
    language = localStorage.getItem('lang')
  }

  if (!language) {
    localStorage.setItem('lang', 'en-US')
  } else {
    let lang = ''
    switch (language) {
      case 'pt':
        lang = 'pt-BR'
        break
      case 'en':
        lang = 'en-US'
        break
      case 'es':
        lang = 'ES'
        break
      case 'ja':
        lang = 'JA'
        break
      case 'ch':
        lang = 'ch-CN'
        break
      case 'ko':
        lang = 'KO'
        break
      case 'tl':
        lang = 'TL'
        break
      case 'th':
        lang = 'TH'
        break
      default:
        lang = ''
    }
    localStorage.setItem('lang', lang)
  }

  i18n.locale = language

  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 50)

  const publicPages = ['/auth/login', '/auth/register', '/auth/recover', '/auth/reset', '/auth/confirm', '/auth/maintenance']
  let authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('refreshToken')
  if (to.hash.includes('#!/auth')) {
    authRequired = false
  }
  if (to.hash.includes('#!/confirm')) {
    if (to.hash.replace('#', '').length > 0) {
      localStorage.removeItem('goTo')
      return next(to.hash.replace('#', '').replace('!', 'auth'))
    }
    localStorage.removeItem('goTo')
    return next()
  }
  if (!authRequired) {
    if (to.hash.replace('#', '').length > 0) {
      return next(to.hash.replace('#', '').replace('!', ''))
    }
    return next()
  }
  try {
    const tokenExpiration = localStorage.getItem('lastTokenExpiration')
    const t = localStorage.getItem('token')
    if (!t || !tokenExpiration || (tokenExpiration && parseInt(tokenExpiration) <= Date.now())) {
      const refresh = localStorage.getItem('refreshToken')
      const { result, token, refreshToken } = await auth.refreshTokenWithTimeout(refresh)
      if (result === 'success') {
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)

        var decoded = jwtDecode(token)
        localStorage.setItem('lastTokenExpiration', decoded.exp.toString() + '000')
        if (to.hash.replace('#', '').length > 0) {
          return next(to.hash.replace('#', '').replace('!', ''))
        }
        localStorage.removeItem('goTo')
        next()
      } else {
        const response = await auth.login('pibofel242@xasems.com', 'NJ^nzktgV*egz6n8ME@a', 'srdh4d5t4564dtg65d5fg4n56dfgn541dcgfn65dg5nsd5b41556kl5jkç5l4gfvnsdxvsd5f65kg5hjkb5jk')
        const { result, token, refreshToken } = response
        switch (result) {
          case 'authenticated':
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            break
          case 'user_or_pass_error':
            return next('/auth/login')
          case 'blocked_user':
            return next('/auth/login')
          case 'invalid_captcha':
            return next('/auth/login')
          case 'email_notConfirmed':
            return next('/auth/login')
          default:
            return next('/auth/maintenance')
        }
        // localStorage.setItem('goTo', to.fullPath)
        // localStorage.removeItem('token')
        // localStorage.removeItem('refreshToken')
        // localStorage.removeItem('lastTokenExpiration')
        // return next('/auth/login')
        if (to.hash.replace('#', '').length > 0) {
          return next(to.hash.replace('#', '').replace('!', ''))
        }
        return next()
      }
    }
    if (to.meta.auth) {
      if (authRequired && loggedIn === null) {
        // localStorage.setItem('goTo', to.fullPath)
        const { result, token, refreshToken } = await auth.login('pibofel242@xasems.com', 'NJ^nzktgV*egz6n8ME@a', 'srdh4d5t4564dtg65d5fg4n56dfgn541dcgfn65dg5nsd5b41556kl5jkç5l4gfvnsdxvsd5f65kg5hjkb5jk')
        switch (result) {
          case 'authenticated':
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            break
          case 'user_or_pass_error':
            return next('/auth/login')
          case 'blocked_user':
            return next('/auth/login')
          case 'invalid_captcha':
            return next('/auth/login')
          case 'email_notConfirmed':
            return next('/auth/login')
          default:
            return next('/auth/maintenance')
        }
        if (to.hash.replace('#', '').length > 0) {
          return next(to.hash.replace('#', '').replace('!', ''))
        }
        return next()
      } else if (to.name === 'client') {
        if (to.hash.replace('#', '').length > 0) {
          return next(to.hash.replace('#', '').replace('!', ''))
        }
        return next()
      }
    }
  } catch (error) {
    const { result, token, refreshToken } = await auth.login('pibofel242@xasems.com', 'NJ^nzktgV*egz6n8ME@a', 'srdh4d5t4564dtg65d5fg4n56dfgn541dcgfn65dg5nsd5b41556kl5jkç5l4gfvnsdxvsd5f65kg5hjkb5jk')
    switch (result) {
      case 'authenticated':
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        break
      case 'user_or_pass_error':
        return next('/auth/login')
      case 'blocked_user':
        return next('/auth/login')
      case 'invalid_captcha':
        return next('/auth/login')
      case 'email_notConfirmed':
        return next('/auth/login')
      default:
        return next('/auth/maintenance')
    }
    if (to.hash.replace('#', '').length > 0) {
      return next(to.hash.replace('#', '').replace('!', ''))
    }
    next()
  }
  if (to.hash.replace('#', '').length > 0) {
    localStorage.removeItem('goTo')
    return next(to.hash.replace('#', '').replace('!', ''))
  }
  localStorage.removeItem('goTo')
  next()
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
