export default {
  state: {
    token: 'AAAAA',
    loading: false
  },
  getters: {
    getToken: state => state.token,
    getLoading: state => state.loading
  },
  actions: {
    renewToken ({ commit }, jwt) {
      commit('setToken', jwt)
    },
    newLoading ({ commit }, bool) {
      commit('setLoading', bool)
    }
  },
  mutations: {
    setToken (state, jwt) {
      state.token = jwt
    },
    setLoading (state, bool) {
      state.loading = bool
    }
  }
}
