<template>
  <div>
    <Loader />
    <div class="alert-container">
      <div
        class="alert alert-light"
        style="cursor:pointer;"
        v-for="(item, index) in localNotificationList"
        :key="index"
        @click="dismissNotification(index)"
      >
        {{ item }}
      </div>
    </div>
    <NavHeader
      :items="headerItem"
      :userprofile="profile"
      :homeURL="{ path: '/' }"
      :logo="logo"
      :key="$route.fullPath"
    >
      <template slot="responsiveRight">
        <ul
          class="d-flex align-items-center justify-content-end list-inline m-0"
        >
          <li class="nav-icon" v-nav-toggle>
            <a href="#" class="search-toggle device-search" @click="showSearch">
              <i class="ri-search-line"></i>
            </a>
            <div
              class="search-box iq-search-bar d-search"
              :style="showSearchBox ? '' : 'visibility:hidden'"
            >
              <form action="#" class="searchbox">
                <input
                  type="text"
                  class="text search-input font-size-12"
                  :placeholder="$t('search.placeholder')"
                  v-model="searchBoxValue"
                  @keydown="validateKeyFromSearchBox($event, searchBoxValue)"
                />
              </form>
              <div class="hover-buttons">
                <span
                  class="btn btn-hover"
                  @click="searchFromSearchBox(searchBoxValue)"
                  >{{ $t("search.button") }}</span
                >
              </div>
            </div>
          </li>
          <li class="nav-item nav-icon" style="display:none;" v-nav-toggle>
            <router-link
              :to="`/${$i18n.locale}/favorites`"
              class="search-toggle position-relative"
            >
              <i class="ri-heart-fill" />
            </router-link>
          </li>
          <li class="nav-item nav-icon" style="display:none;" v-nav-toggle>
            <router-link
              :to="`/${$i18n.locale}/mylist`"
              class="search-toggle position-relative"
            >
              <i class="ri-add-circle-fill" />
            </router-link>
          </li>
          <li class="nav-item nav-icon" style="display:none;" v-nav-toggle>
            <a
              href="javascript:void(0)"
              class="search-toggle position-relative active"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="22"
                height="22"
                class="noti-svg"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M18 10a6 6 0 1 0-12 0v8h12v-8zm2 8.667l.4.533a.5.5 0 0 1-.4.8H4a.5.5 0 0 1-.4-.8l.4-.533V10a8 8 0 1 1 16 0v8.667zM9.5 21h5a2.5 2.5 0 1 1-5 0z"
                />
              </svg>
              <span v-if="nots.length > 0" class="bg-danger dots"></span>
            </a>
            <div class="iq-sub-dropdown" v-if="nots.length > 0">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body  ">
                  <Notification
                    v-for="n in nots"
                    :el="n"
                    :key="n.uid"
                    @read="readNotification"
                  />
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item nav-icon" v-nav-toggle>
            <img
              class="iq-user-dropdown pointer search-toggle p-0 d-flex align-items-center"
              :src="getLanguageUrlFromAbbreviation()"
              style="width:27px;margin-top:2px"
            />
            <div class="iq-sub-dropdown iq-language-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0" style="margin: 0px 5px;">
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('en')"
                    v-if="activeLanguages.includes('en-US')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('en')"
                      style="width:40px"
                    />
                    <span> English</span>
                  </div>
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('es')"
                    v-if="activeLanguages.includes('ES')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('es')"
                      style="width:40px"
                    />
                    <span> Spanish</span>
                  </div>
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('pt')"
                    v-if="activeLanguages.includes('pt-BR')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('pt')"
                      style="width:40px"
                    />
                    <span> Portuguese</span>
                  </div>
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('ch')"
                    v-if="activeLanguages.includes('ch-CN')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('ch')"
                      style="width:40px"
                    />
                    <span> Chinese</span>
                  </div>
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('ko')"
                    v-if="activeLanguages.includes('KO')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('ko')"
                      style="width:40px"
                    />
                    <span> Korean</span>
                  </div>
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('ja')"
                    v-if="activeLanguages.includes('JA')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('ja')"
                      style="width:40px"
                    />
                    <span> Japanese</span>
                  </div>
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('tl')"
                    v-if="activeLanguages.includes('TL')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('tl')"
                      style="width:40px"
                    />
                    <span> Tagalog</span>
                  </div>
                  <div
                    class="iq-sub-card language-dropdown"
                    @click="setLocale('th')"
                    v-if="activeLanguages.includes('TH')"
                  >
                    <img
                      :src="getLanguageUrlFromAbbreviation('th')"
                      style="width:40px"
                    />
                    <span> Thai</span>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="nav-item nav-icon" style="display:none;" v-nav-toggle>
            <a
              href="#"
              class="iq-user-dropdown search-toggle p-0 d-flex align-items-center"
            >
              <img
                :src="photoUrl"
                class="img-fluid avatar-40 rounded-circle"
                alt="user"
              />
            </a>
            <div class="iq-sub-dropdown iq-user-dropdown">
              <div class="iq-card shadow-none m-0">
                <div class="iq-card-body p-0 pl-3 pr-3">
                  <a
                    :href="
                      'https://app.iamgo.pro/account/profile?token=' +
                        currentToken
                    "
                    class="iq-sub-card setting-dropdown"
                  >
                    <div class="media align-items-center">
                      <div class="right-icon">
                        <i class="ri-settings-4-line text-primary"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="my-0 ">{{ $t("settings.title") }}</h6>
                      </div>
                    </div>
                  </a>
                  <a
                    @click="logout"
                    class="iq-sub-card setting-dropdown"
                    style="cursor: pointer"
                  >
                    <div class="media align-items-center">
                      <div class="right-icon">
                        <i class="ri-logout-circle-line text-primary"></i>
                      </div>
                      <div class="media-body ml-3">
                        <h6 class="my-0 ">{{ $t("general.logout") }}</h6>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </template>
    </NavHeader>
    <Home
      id="home"
      v-if="validateRouteForHome && highlights.length > 0"
      :key="currentWidth"
      :highlights="highlights"
    />
    <Slider v-if="this.$route.meta.category" :highlights="highlights" />
    <MovieSlider v-if="false" :key="fullPath2" :highlights="highlights" />
    <BannerVideo
      v-if="
        this.$route.name == 'landing-page.movie-detail' ||
          this.$route.name == 'landing-page.category-detail'
      "
      :key="$fullPath3"
    />
    <Banner
      v-if="this.$route.name == 'landing-page.show-single'"
      :key="fullPath4"
    />
    <Breadcrumb
      v-if="
        this.$route.name == 'landing-page.about' ||
          this.$route.name == 'landing-page.contact' ||
          this.$route.name == 'landing-page.privacypolicy' ||
          this.$route.name == 'landing-page.pricingplan1' ||
          this.$route.name == 'landing-page.pricingplan2' ||
          this.$route.name == 'landing-page.faq'
      "
      :key="fullPath5"
    />
    <div
      :class="
        validateRouteForHome && highlights.length > 0 ? '' : 'main-content'
      "
    >
      <transition
        name="router-anim"
        :enter-active-class="`animated ${animated.enter}`"
        mode="out-in"
        :leave-active-class="`animated ${animated.exit}`"
      >
        <router-view
          :key="fullPath6"
          :currentWidth="windowWidth"
          :highlights="highlights"
          :searchString="searchBoxValue"
          @addFavorite="addFavorite"
          @removeFavorite="removeFavorite"
          @addMyList="addMyList"
          @removeMyList="removeMyList"
        />
      </transition>
    </div>
    <Footer style="margin-top:80px" :key="fullPath7" />
    <div id="back-to-top">
      <a class="top" href="#top" id="top"> <i class="fa fa-angle-up"></i> </a>
    </div>
  </div>
</template>
<script>
import videoList from '../services/videoList'
import userSettings from '../services/userSettings'
import auth from '../services/auth'
import notifications from '../services/notifications'
import jwtDecode from 'jwt-decode'
import { core } from '../config/pluginInit'
import Loader from '../components/core/loader/Loader'
import profile from '../assets/images/frontend/user/user.jpg'
import loader from '../assets/images/logo-full.png'
import Footer from '../views/FrontendPages/Components/Footer/Footer'
import Home from '../views/FrontendPages/Components/Home/Home'
import Slider from '../views/FrontendPages/CategoryPage/Slider'
import MovieSlider from '../views/FrontendPages/MovieCategoryPage/Slider'
import NavHeader from '../components/core/navbars/FrontendNav'
import Notification from './private/Notification'
import BannerVideo from '../views/FrontendPages/MovieDetailPage/BannerVideo'
import Banner from '../views/FrontendPages/ShowSinglePage/Banner'
import Breadcrumb from '../views/FrontendPages/Components/Breadcrumb/Breadcrumb'
import { mapGetters, mapActions } from 'vuex'

import ptImage from '../assets/images/frontend/languages/BR-PT.png'
import esImage from '../assets/images/frontend/languages/ES-ES.png'
import enImage from '../assets/images/frontend/languages/US-EN.png'
import chImage from '../assets/images/frontend/languages/CH-CN.png'
import jaImage from '../assets/images/frontend/languages/JA.png'
import tlImage from '../assets/images/frontend/languages/TL.png'
import thImage from '../assets/images/frontend/languages/TH.png'
import koImage from '../assets/images/frontend/languages/KO.png'

export default {
  name: 'FrontendLayout',
  components: {
    Footer,
    Loader,
    Home,
    NavHeader,
    Notification,
    Slider,
    MovieSlider,
    BannerVideo,
    Banner,
    Breadcrumb
  },
  mounted () {
    core.index()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  async beforeMount () {
    const refreshToken = async () => {
      const tokenExpiration = localStorage.getItem('lastTokenExpiration')
      const r = localStorage.getItem('refreshToken')
      this.currentToken = localStorage.getItem('token')
      if (
        !tokenExpiration ||
        (tokenExpiration && parseInt(tokenExpiration) <= Date.now())
      ) {
        try {
          if (this.getLoading) {
            return
          }
          this.setLoading(true)
          const {
            result,
            token,
            refreshToken
          } = await auth.refreshTokenWithTimeout(r)
          this.setLoading(false)
          var decoded
          if (result === 'success') {
            this.currentToken = token
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            decoded = jwtDecode(token)
            localStorage.setItem(
              'lastTokenExpiration',
              decoded.exp.toString() + '000'
            )
          }
        } catch (error) {}
      }
      setTimeout(refreshToken, 61000)
    }
    this.searchBoxValue = this.$route.query.q || ''
    refreshToken()
    const res = await notifications.getNotifications()
    if (res.result === 'success') {
      this.nots = res.notifications
    }
    let spotlightCat

    switch ('/' + this.$router.currentRoute.path.split('/').pop()) {
      case '/promo':
        spotlightCat = 'promo'
        break
      case '/news':
        spotlightCat = 'news'
        break
      case '/tutorial':
        spotlightCat = 'tutorial'
        break
      case '/motivation':
        spotlightCat = 'motivational'
        break
      default:
        spotlightCat = ''
        break
    }
    const calls = [videoList.getCategoriesToDisplay(), videoList.getSpotlightList(spotlightCat), videoList.getLanguagesToDisplay()]
    const responses = await Promise.all(calls)

    if (responses[0].result === 'success') {
      this.activeCategories = responses[0].categories.map(c => c.categoryName) || []
      const newHeader = []
      newHeader.push({
        title: this.$t('home.title'),
        link: `/${this.$i18n.locale}/`,
        child: false
      })
      if (this.activeCategories.includes('News')) {
        newHeader.push({
          title: this.$t('news.title'),
          link: `/${this.$i18n.locale}/news`,
          child: false
        })
      }
      if (this.activeCategories.includes('Tutorial')) {
        newHeader.push({
          title: this.$t('tutorial.title'),
          link: `/${this.$i18n.locale}/tutorial`,
          child: false
        })
      }
      if (this.activeCategories.includes('Motivational')) {
        newHeader.push({
          title: this.$t('motivation.title'),
          link: `/${this.$i18n.locale}/motivation`,
          child: false
        })
      }
      // newHeader.push({
      //   title: this.$t('library.title'),
      //   link: `/${this.$i18n.locale}/library`,
      //   child: false
      // })
      this.headerItem = newHeader
    }
    if (responses[1].result === 'success') {
      this.highlights = responses[1].video_list || []
    }
    if (responses[2].result === 'success') {
      this.activeLanguages = responses[2].languages.map(c => c.languageCode) || []
    }
    const resU = await userSettings.GetProfileInfo()
    if (resU.result === 'success') {
      this.photoUrl = resU.url
      const locale = localStorage.getItem('lang').substring(0, 2)
      if (this.$i18n.locale !== locale.toLowerCase()) {
        this.$i18n.locale = locale.toLowerCase()
        this.$router.push(`/${locale.toLowerCase()}`)
      }
    }
  },
  beforeUpdate () {
    const refreshToken = async () => {
      const tokenExpiration = localStorage.getItem('lastTokenExpiration')
      const r = localStorage.getItem('refreshToken')
      this.currentToken = localStorage.getItem('token')
      if (
        !tokenExpiration ||
        (tokenExpiration && parseInt(tokenExpiration) <= Date.now())
      ) {
        try {
          if (this.getLoading) {
            return
          }
          this.setLoading(true)
          const {
            result,
            token,
            refreshToken
          } = await auth.refreshTokenWithTimeout(r)
          this.setLoading(false)
          var decoded
          if (result === 'success') {
            this.currentToken = token
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            decoded = jwtDecode(token)
            localStorage.setItem(
              'lastTokenExpiration',
              decoded.exp.toString() + '000'
            )
          }
        } catch (error) {}
      }
      setTimeout(refreshToken, 61000)
    }
    refreshToken()
  },
  data () {
    return {
      currentToken: '',
      searchBoxValue: '',
      showSearchBox: false,
      nots: [],
      highlights: [],
      activeCategories: [],
      activeLanguages: [],
      profile: '',
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      userProfile: profile,
      onlyLogo: false,
      onlyLogoText: false,
      logo: loader,
      headerItem: [
        // { title: this.$t('be-pro.title'), link: '/pricing', child: false }
      ],
      photoUrl: '',
      windowWidth: window.innerWidth,
      localNotificationList: []
    }
  },
  methods: {
    ...mapActions(['newLoading']),
    async readNotification (uid) {
      await notifications.setNotifications(uid)
      setTimeout(() => {
        this.nots = this.nots.filter((n) => {
          return n.uid !== uid
        })
      }, 5000)
    },
    changeLogo (e) {
      this.logo = e
    },
    routerAnimationChange (e) {
      this.animated = e
    },
    showSearch () {
      this.showSearchBox = !this.showSearchBox
    },
    async addFavorite (uid) {
      // toggle no status do vídeo
      const test = this.highlights.map((h) => {
        if (h.uid === uid) {
          return { ...h, isFavorite: true }
        } else {
          return h
        }
      })
      this.highlights = test
      // adicionar notificação
      this.localNotificationList.push(
        this.$t('general.video-added-to-favorites').toString()
      )
    },
    async removeFavorite (uid) {
      // toggle no status do vídeo
      this.highlights = this.highlights.map((h) => {
        if (h.uid === uid) {
          return { ...h, isFavorite: false }
        } else {
          return h
        }
      })
      // adicionar notificação
      this.localNotificationList.push(
        this.$t('general.video-removed-from-favorites').toString()
      )
    },
    async addMyList (uid) {
      // toggle no status do vídeo
      const test = this.highlights.map((h) => {
        if (h.uid === uid) {
          return { ...h, isMyList: true }
        } else {
          return h
        }
      })
      this.highlights = test
      // adicionar notificação
      this.localNotificationList.push(
        this.$t('general.video-added-to-mylist').toString()
      )
    },
    async removeMyList (uid) {
      // toggle no status do vídeo
      this.highlights = this.highlights.map((h) => {
        if (h.uid === uid) {
          return { ...h, isMyList: false }
        } else {
          return h
        }
      })
      // adicionar notificação
      this.localNotificationList.push(
        this.$t('general.video-removed-from-mylist').toString()
      )
    },
    addNotification (title, description, url) {
      this.nots = this.nots.push({
        uid: '',
        title: title,
        description: description,
        type: '',
        application: '',
        url: '' + url
      })
    },
    searchFromSearchBox (text) {
      if (text && text.length > 0) {
        this.$router.push(`/${this.$i18n.locale}/search?q=` + text)
        return
      }
      if ('/' + this.$router.currentRoute.path.split('/').pop() === '/search') {
        return this.$router.push('/')
      }
    },
    validateKeyFromSearchBox (e, text) {
      if (e.key === 'Enter' || e.keyCode === 13) {
        e.preventDefault()
        if (text && text.length > 0) {
          this.$router.push(`/${this.$i18n.locale}/search?q=` + text)
          return
        }
        if (
          '/' + this.$router.currentRoute.path.split('/').pop() ===
          '/search'
        ) {
          this.$router.push(`/${this.$i18n.locale}/`)
        }
      }
    },
    logout () {
      localStorage.removeItem('token')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('lastTokenExpiration')
      this.$router.push({ path: '/auth/login' })
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    getLanguageUrlFromAbbreviation (str) {
      switch (str) {
        case 'pt':
          return ptImage
        case 'en':
          return enImage
        case 'es':
          return esImage
        case 'ch':
          return chImage
        case 'ja':
          return jaImage
        case 'ko':
          return koImage
        case 'tl':
          return tlImage
        case 'th':
          return thImage
        default:
          return this.getLanguageUrlFromAbbreviation(this.$i18n.locale)
      }
    },
    async setLocale (str) {
      let lang = ''
      switch (str) {
        case 'pt':
          lang = 'pt-BR'
          break
        case 'en':
          lang = 'en-US'
          break
        case 'es':
          lang = 'ES'
          break
        case 'ja':
          lang = 'JA'
          break
        case 'ch':
          lang = 'ch-CN'
          break
        case 'ko':
          lang = 'KO'
          break
        case 'tl':
          lang = 'TL'
          break
        case 'th':
          lang = 'TH'
          break
        default:
          lang = ''
      }
      if (lang.length <= 0) {
        return
      }
      localStorage.setItem('lang', lang)
      this.$i18n.locale = str
      this.$router.push(`/${str}`)
    },
    dismissNotification (index) {
      this.localNotificationList = this.localNotificationList.filter(
        (v, i) => i !== index
      )
    }
  },
  watch: {
    $route: async function (to, from) {
      let spotlightCat

      switch ('/' + this.$router.currentRoute.path.split('/').pop()) {
        case '/promo':
          spotlightCat = 'promo'
          break
        case '/tutorial':
          spotlightCat = 'tutorial'
          break
        case '/motivation':
          spotlightCat = 'motivational'
          break
        default:
          spotlightCat = ''
          break
      }
      const response = await videoList.getSpotlightList(spotlightCat)
      if (response.result === 'success') {
        this.highlights = response.video_list
      }
    },
    localNotificationList: function (to, from) {
      setTimeout(() => {
        this.localNotificationList = this.localNotificationList.filter(
          (v, i) => i !== 0
        )
      }, 5000)
    }
  },
  computed: {
    fullPath1 () {
      return '1' + this.$route.fullPath
    },
    fullPath2 () {
      return '2' + this.$route.fullPath
    },
    fullPath3 () {
      return '3' + this.$route.fullPath
    },
    fullPath4 () {
      return '4' + this.$route.fullPath
    },
    fullPath5 () {
      return '5' + this.$route.fullPath
    },
    fullPath6 () {
      return '6' + this.$route.fullPath
    },
    fullPath7 () {
      return '7' + this.$route.fullPath
    },
    validateRouteForHome () {
      return (
        this.$router.currentRoute.path.split('/').pop().length === 2 ||
        this.$router.currentRoute.path.split('/').pop() === '' ||
        this.$router.currentRoute.path.split('/').pop() === 'promo' ||
        this.$router.currentRoute.path.split('/').pop() === 'news' ||
        this.$router.currentRoute.path.split('/').pop() === 'tutorial' ||
        this.$router.currentRoute.path.split('/').pop() === 'motivation'
      )
    },
    validateRouteForHomelength () {
      return this.$router.currentRoute.path.split('/').pop().length === 2
    },
    validateRouteForHomeEmpty () {
      return this.$router.currentRoute.path.split('/').pop() === ''
    },
    validateRouteForHomePromo () {
      return this.$router.currentRoute.path.split('/').pop() === 'promo'
    },
    validateRouteForHomeTutorial () {
      return this.$router.currentRoute.path.split('/').pop() === 'tutorial'
    },
    validateRouteForHomeMotivation () {
      return this.$router.currentRoute.path.split('/').pop() === 'motivation'
    },
    currentWidth () {
      return this.windowWidth.toString()
    },
    ...mapGetters(['getLoading'])
  }
}
</script>
<style lang='scss'>
@import '../assets/css/custom.css';
@import '../assets/scss/frontend.scss';
@import '../assets/css/developer.css';
.search-box.iq-search-bar.d-search {
  display: flex;
  flex-direction: row;
}

.search-box.iq-search-bar.d-search .hover-buttons {
  max-height: 40px;
}
.search-box.iq-search-bar.d-search .hover-buttons .btn-hover {
  padding: 9px !important;
  padding-bottom: 10px !important;
}
.grecaptcha-badge {
  visibility: hidden;
}
.alert-container {
  position: fixed;
  top: 100px;
  right: 20px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
}
.alert-container {
  position:fixed;
  top: 100px;
  right: 20px;
  z-index: 99999;
  display: flex;
  flex-direction: column;
}
</style>
