<template>
      <div class="container">
        <div class="row justify-content-center align-items-center height-self-center">
            <div class="col-lg-5 col-md-12 align-self-center">
              <div class="sign-user_card ">
                  <div class="sign-in-page-data">
                    <div class="sign-in-from w-100 m-auto">
                        <h3 class="mb-3">Reset Password</h3>
                        <div class="alert alert-danger" v-if="message" role="alert">
                          <span >{{ message }}</span>
                        </div>
                        <div class="alert alert-primary" v-if="messageSuccess" role="alert">
                          <span >{{ messageSuccess }}</span>
                        </div>
                        <p class="text-body textStyle">Enter your email address and we'll send you an email with instructions to reset your password.</p>
                        <form id="recover" class="mt-4">
                          <div class="form-group">
                            <input type="email" class="form-control mb-0" id="exampleInputEmail2" v-model="email" placeholder="Enter email" autocomplete="off" required>
                          </div>
                          <div class="sign-info">
                            <router-link to="/auth/login" class="btn btn-hover returnButtonStyle grey" >Return</router-link>
                            <button v-if="!loading && !messageSuccess" class="btn btn-hover buttonStyle " @click="recaptcha">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>
                            Send</button>
                          </div>
                        </form>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
</template>
<script>
import auth from '../../services/auth'
export default {
  name: 'Recover',
  data: () => {
    return {
      email: '',
      message: undefined,
      messageSuccess: undefined,
      loading: false
    }
  },
  methods: {
    async recaptcha (e) {
      this.loading = true
      const form = document.getElementById('recover')
      const elements = form.elements
      // (optional) Wait until recaptcha has been loaded.
      e.preventDefault()
      // eslint-disable-next-line
      await grecaptcha.ready(() => {
        // eslint-disable-next-line
        grecaptcha.execute('6LfMA64aAAAAAFByMyPhKQJMynWvuqyTnH_ro57I', { action: 'recover' }).then(async (token) => {
          // Do stuff with the received token.
          const response = await auth.recover(this.email, token)

          switch (response) {
            case 'success':
              this.messageSuccess = 'Link sent to your email.'
              this.message = undefined
              for (let i = 0, len = elements.length; i < len; ++i) {
                elements[i].readOnly = true
              }
              break
            case 'email_not_found':
              this.message = 'Email not found'
              this.messageSuccess = undefined
              break
            case 'error':
              this.message = 'Server error'
              this.messageSuccess = undefined
              break
            case 'invalid_captcha':
              this.message = 'Invalid Captcha'
              this.messageSuccess = undefined
              break
            default:
              this.message = 'Server error.'
              this.messageSuccess = undefined
              break
          }
          this.loading = false
        })
      })
    }
  }
}
</script>

<style scoped>
  input{
    background-color: rgba(65,65,65,0.480)
  }
  .buttonStyle{
    background-color: #bf000a;
    color: honeydew;
  }
  .textStyle{
    color: honeydew !important;
  }
  .grey{
    background-color: #dddddd;
    color: black;
  }
  label {
    color: white!important;
  }
  .returnButtonStyle{
    background-color: #cecece;
    color: black;
  }
  .btn-hover {
    border-color: #cecece!important;
  }
  .btn-hover.returnButtonStyle::before {
    background-color: #d3d3d3!important;
    border-color: #cecece!important;
    color: black!important;
  }
</style>
