<template>
  <form id="register-form" class="" action="index.html">
    <div class="alert alert-danger" v-if="message" role="alert">
      <span >{{ message }}</span>
    </div>
    <div v-if="!registered" class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>First Name</label>
          <input type="text" class="form-control mb-0 textStyle" id="firstname-input" placeholder="First Name" autocomplete="off" v-model="firstName" required :readonly="messageSuccess || loading? true: false">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Last Name</label>
          <input type="text" class="form-control mb-0 textStyle" id="lastname-input" placeholder="Last Name" autocomplete="off" v-model="lastName" required :readonly="messageSuccess || loading? true: false">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>E-mail</label>
          <input type="email" class="form-control mb-0 textStyle" id="email-input" placeholder="Enter email" autocomplete="off"  v-model="email" required :readonly="messageSuccess || loading? true: false">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Language</label>
          <v-select :options="languageList" :reduce="languageName => languageName.languageCode" v-model="language" label="languageName" transition=""></v-select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Password</label>
          <i :class="(showPass?'ri-eye-line ri-xl red':'ri-eye-line ri-xl ')" id="togglePassword" @click="togglePassword"></i>
          <input :type="showPass?'text':'password'"  class="form-control mb-0 textStyle" id="exampleInputPassword2" placeholder="Password"  v-model="password" required :readonly="messageSuccess || loading? true: false">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Repeat Password</label>
          <i :class="(showPass?'ri-eye-line ri-xl red':'ri-eye-line ri-xl ')" id="togglePassword" @click="togglePassword"></i>
          <input :type="showPass?'text':'password'"  class="form-control mb-0 textStyle" id="exampleInputPassword2" placeholder="Password" v-model="repeatedPass" required :readonly="messageSuccess || loading? true: false">
        </div>
      </div>
    </div>
    <div class="alert alert-info" v-if="messageSuccess" role="alert">
      <h3 style="color:black!important" >{{ messageSuccess }}</h3 >
    </div>
    <br />
    <button v-if="!registered" class="btn btn-hover btn-hover my-2" @click="recaptcha" >
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>
      Sign Up
    </button>
    <button v-else class="btn btn-hover btn-hover my-2" @click="resend" >
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>
      Resend
    </button>

  </form>
</template>

<script>
import auth from '../../services/auth'
import vSelect from 'vue-select'

export default {
  name: 'SignUp1Form',
  components: {
    vSelect
  },
  data: () => {
    return {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatedPass: '',
      language: 'init',
      message: undefined,
      messageSuccess: undefined,
      languageList: [],
      loading: false,
      registered: false,
      showPass: false
    }
  },
  async beforeMount () {
    const response = await auth.getListLanguages()
    if (response.result === 'success') {
      this.languageList = response.languages.concat({ languageName: 'Please Select A Language', languageCode: '' })
      this.language = ''
    }
  },
  methods: {
    async recaptcha (e) {
      this.loading = true
      // (optional) Wait until recaptcha has been loaded.
      e.preventDefault()
      const firstName = this.firstName
      const lastName = this.lastName
      const email = this.email
      const password = this.password
      const language = this.language

      // validation:
      if (!firstName || firstName.length <= 0) {
        this.message = 'Invalid name or surname'
        this.messageSuccess = undefined
        this.loading = false
        return
      }
      if (!lastName || lastName.length <= 0) {
        this.message = 'Invalid name or surname'
        this.messageSuccess = undefined
        this.loading = false
        return
      }
      if (!email || email.length <= 0) {
        this.message = 'Invalid email'
        this.messageSuccess = undefined
        this.loading = false
        return
      }
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!re.test(String(email).toLowerCase())) {
        this.message = 'Invalid email'
        this.messageSuccess = undefined
        this.loading = false
        return
      }
      if (!password || password.length <= 5) {
        this.message = 'Password must have at least 6 digits'
        this.messageSuccess = undefined
        this.loading = false
        return
      }
      if (password !== this.repeatedPass) {
        this.message = 'Repeated password does not match'
        this.messageSuccess = undefined
        this.loading = false
        return
      }
      if (!language || language.length <= 0) {
        this.message = 'Invalid language'
        this.messageSuccess = undefined
        this.loading = false
        return
      }

      // eslint-disable-next-line
      await grecaptcha.ready(() => {
        // eslint-disable-next-line
        grecaptcha.execute('6LfMA64aAAAAAFByMyPhKQJMynWvuqyTnH_ro57I', { action: 'register' }).then(async (token) => {
          // Do stuff with the received token.
          const response = await auth.register(firstName, lastName, email, password, language, token)

          switch (response) {
            case 'success':
              this.message = undefined
              this.messageSuccess = 'Success! check your email to validate the account.'
              this.registered = true
              break
            case 'invalid_email':
              this.message = 'Invalid email'
              this.messageSuccess = undefined
              break
            case 'invalid_name':
              this.message = 'Invalid name or surname'
              this.messageSuccess = undefined
              break
            case 'registered_email':
              this.message = 'email already registered'
              this.messageSuccess = undefined
              break
            case 'password_error':
              this.message = 'Invalid Password'
              this.messageSuccess = undefined
              break
            case 'invalid_captcha':
              this.message = 'Invalid captcha'
              this.messageSuccess = undefined
              break
            case 'invalid_language':
              this.message = 'Invalid language'
              this.messageSuccess = undefined
              break
            default:
              this.message = 'error.'
              this.messageSuccess = undefined
              break
          }
          this.loading = false
        })
      })
    },
    togglePassword () {
      this.showPass = !this.showPass
    },
    async resend (e) {
      this.loading = true
      // (optional) Wait until recaptcha has been loaded.
      e.preventDefault()
      // eslint-disable-next-line
      await grecaptcha.ready(() => {
        // eslint-disable-next-line
        grecaptcha.execute('6LfMA64aAAAAAFByMyPhKQJMynWvuqyTnH_ro57I', { action: 'resend' }).then(async (token) => {
          // Do stuff with the received token.
          const response = await auth.resend(this.email, token)

          switch (response) {
            case 'success':
              this.message = undefined
              this.messageSuccess = 'Success! check your email again.'
              break
            case 'errorSendEmail':
              this.message = 'Email could not be sent.'
              this.messageSuccess = undefined
              break
            case 'userNotFound':
              this.message = 'User not found.'
              this.messageSuccess = undefined
              break
            default:
              this.message = 'error.'
              this.messageSuccess = undefined
              break
          }
          this.loading = false
        })
      })
    }
  }
}

</script>
<style scoped>
input{
  background-color: rgba(65, 65, 65, 0.480);
}
.textStyle{
  color: honeydew;
}
.btn-hover{ background: var(--iq-primary-hover);
  border: 1px solid var(--iq-primary-hover);
  color: var(--iq-white);
  transition: color 0.3s ease;
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  border-radius: 5px;
}
  label {
    color: white!important;
  }
  .ri-eye-line {
    cursor: pointer;
    position: absolute;
    z-index: 999;
    height:2em;
    right: 0;
    padding:15px;
    margin-top: 32px;
    margin-right:15px;
  }
  .red {
    color: #bf000a
  }
</style>
