<template>
<section class="m-profile setting-wrapper">
    <b-container>
        <h4 class="main-title mb-4">{{$t('checkout.main-heading')}}</h4>
        <b-row>
            <b-col lg="12">
                <div class="sign-user_card">
                    <div class="row justify-content-between mb-3">
                        <div class="col-md-12 r-mb-15">
                          <div class="row mb-3">
                            <h4 class="col-md-6">{{$t('checkout.plan')}}: {{invoicePlan}}</h4>
                            <h4 class="col-md-6">{{$t('checkout.value')}}: {{invoiceAmount}}</h4>
                          </div>
                          <div class="row mb-3">
                            <h4 class="col-md-12">{{$t('checkout.hash')}}: {{gatewayCode}}</h4>
                          </div>
                          <div class="row m-3">
                            <p class="col-md-12">{{$t('checkout.text')}}</p>
                          </div>
                          <div class="row">
                            <div class="col-md-8 mb-3" style="margin-left:auto!important;">
                            <label style="color:white"> {{$t('checkout.qrcode')}}</label>
                                <qrcode-vue class="teste" :value="gatewayCode" :size="300" level="H" />
                            </div>
                          </div>
                          <div class="row">
                            <a href="#" class="btn btn-hover col-md-6 col-md-offset-3" style="float:none;margin:auto;">{{$t('checkout.how-to')}}</a>
                          </div>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </b-container>
</section>
</template>
<script>
import payment from '../../services/payment'
import { core } from '../../config/pluginInit'
import QrcodeVue from 'qrcode.vue'
export default {
  name: 'Settings',
  components: {
    QrcodeVue
  },
  async beforeMount () {
    const response = await payment.GetCheckout()
    if (response.result === 'success') {
      this.invoicePlan = response.invoice.invoicePlan
      this.invoiceAmount = response.invoice.invoiceAmount
      this.invoiceIssueDate = response.invoice.invoiceIssueDate
      this.gatewayCode = response.invoice.gatewayCode
    }
  },
  mounted () {
    core.index()
  },
  updated () {
  },
  data () {
    return {
      hasUnpaidInvoice: undefined,
      invoicePlan: '',
      invoiceAmount: '',
      invoiceIssueDate: '',
      gatewayCode: ''
    }
  },
  methods: {
  }
}
</script>
