<template>
                    <div class="tranding-block position-relative"  :style="{backgroundImage:`url(${detail.bannerImageUrl})`, backgroundRepeat: 'no-repeat',
backgroundSize: 'cover'}">
                      <div  class="trending-custom-tab">
                        <div class="tab-title-info position-relative">
                            <div @click="clickDefault()" class="tab-title-info position-relative">
                              <tab-nav class="trending-pills tranding-tab-nav text-center align-items-center d-flex" :pills="true" align="center" role="tablist">
                                <tab-nav-items  ariaControls="trending-data1" :active="showDefault" :title="$t('general.overview')" dataToggle="pill" role="tab" :ariaSelected="true"/>
                                <tab-nav-items ariaControls="trending-data2"  :active="!showDefault" :title="$t('general.episodes')" dataToggle="pill" role="tab" :ariaSelected="false"/>
                              </tab-nav>
                            </div>
                            <tab-content class="trending-content">
                              <tab-content-item id="trending-data1" class="overview-tab" :active="showDefault">
                                <div class="trending-info align-items-center w-100 animated fadeInUp">
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <a href="javascript:void(0);" tabindex="0">
                                      <div class="res-logo">
                                          <div class="channel-logo">
                                            <img src="../../../../assets/images/logo-full.png" class="c-logo" alt="iamgo">
                                          </div>
                                      </div>
                                    </a>
                                    <h1 class="trending-text big-title text-uppercase">{{detail.title}}</h1>
                                    <br />
                                    <div class="trending-dec" v-html="detail.longDescription"></div>
                                    <div class="p-btns">
                                      <div class="d-flex align-items-center p-0">
                                        <router-link :to="watchVideo(suggested(detail)[0].uid)" class="btn btn-hover"><i class="fa fa-play mr-2"
                                            aria-hidden="true"></i>{{$t('general.play-now')}}</router-link>
                                      </div>
                                    </div>
                                    <div class="trending-list mt-4">
                                      <div class="text-primary title">{{$t('video.author')}}: <span class="text-body">{{detail.autor}}</span>
                                      </div>
                                      <div class="text-primary title">{{$t('video.genres')}}: <span class="text-body">{{detail.genre}}</span>
                                      </div>
                                      <div class="text-primary title" v-if="detail.tags">{{$t('video.tags')}}: <span class="text-body">{{detail.tags}}</span>
                                      </div>
                                      <div class="text-primary title">{{$t('general.seasons')}}: <span class="text-body">{{detail.seasons && detail.seasons.length}}</span>
                                      </div>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                              </tab-content-item>
                              <tab-content-item id="trending-data2" class="overview-tab" :active="!showDefault">
                                <div
                                    class="trending-info align-items-center w-100 animated fadeInUp">
                                    <router-link to="/" tabindex="0">
                                      <div class="channel-logo">
                                          <img src="../../../../assets/images/logo-full.png" class="c-logo" alt="iamgo">
                                      </div>
                                    </router-link>
                                    <h1 class="trending-text big-title text-uppercase">{{detail.title}}</h1>
                                    <div class="iq-custom-select d-inline-block sea-epi">
                                      <V-select style="right:0" :key="currentSeason" :options="seasonOption(detail)" v-model="currentSeason">
                                      </V-select>
                                    </div>
                                    <br />
                                    <div class="trending-dec" style="padding-left:0px;margin-top:12px;" v-html="detail.seasons[parseInt(currentSeason)] && detail.seasons[(parseInt(currentSeason))].seasonDescription"></div>

                                    <Suggested v-if="Array.isArray(suggested(detail)) &&  suggested(detail).length > 0" :key="reRenderEpisodes.toString()" :suggested="suggested(detail)" @addFavorite="addFavorite" @removeFavorite="removeFavorite" />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                              </tab-content-item>
                            </tab-content>
                         </div>
                      </div>
                    </div>
</template>
<script>
import videoList from '../../../../services/videoList'
import Suggested from '../Suggested/Episodes'

export default {
  name: 'Details',
  components: {
    Suggested
  },
  props: ['detail'],
  mounted () {
  },
  methods: {
    async addFavorite (uid) {
      const res = await videoList.postAddFavorite(uid)
      if (res.result === 'success') {
        // this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: true } } else { return h } })
        if (this.video.uid === uid) {
          this.video.isFavorite = true
        }
        this.$emit('addFavorite', uid)
      }
    },
    async removeFavorite (uid) {
      const res = await videoList.postRemoveFavorite(uid)
      if (res.result === 'success') {
        // this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: false } } else { return h } })
        if (this.video.uid === uid) {
          this.video.isFavorite = false
        }
        this.$emit('removeFavorite', uid)
      }
    },
    watchVideo (id) {
      return (`/${this.$i18n.locale}/watch?v=` + id)
    },
    suggested (show) {
      return (show.seasons && show.seasons[this.currentSeason].videos) || {}
    },
    seasonOption (show) {
      return (show && show.seasons && show.seasons.map((s, i) => { return { id: i, value: s, text: this.$t('general.season') + ' ' + (i + 1) } }))
    },
    clickDefault () {
      setTimeout(() => {
        this.reRenderEpisodes += 1
      }, 500)
    }
  },
  data () {
    return {
      navStatus: '',
      currentSeason: 0,
      showDefault: true,
      reRenderEpisodes: 0
    }
  },
  computed: {
  },
  watch: {
    showDefault () {
      setTimeout(() => {
        this.reRenderEpisodes += 1
      }, 500)
      this.componentKey += 1
    }
  }
}
</script>

<style scoped>
.slick-slide.slick-cloned{
  width:21vw;
}
.slick-track{
  width:90vw!important;
  min-height: 100vh!important;
}
.slider-detail{
  width:90vw!important;
  min-height: 100vh!important;
}
</style>
