import SignIn1 from './page/public/SignIn1'
import Register from './page/public/SignUp1'
import Recover from './page/public/RecoverPassword1'
import Reset from './page/public/RecoverPassword2'
import Confirm from './page/public/ConfirmMail1'
import Maintenance from './page/public/Maintenance'

export default [
  {
    path: '',
    component: SignIn1
  },
  {
    path: 'login',
    component: SignIn1
  },
  {
    path: 'register',
    component: Register
  },
  {
    path: 'recover',
    component: Recover
  },
  {
    path: 'reset',
    component: Reset
  },
  {
    path: 'confirm',
    component: Confirm
  },
  {
    path: 'maintenance',
    component: Maintenance
  }
]
