<template>
  <div
  >
    <b-container class>
      <b-row
        class="justify-content-center align-items-center height-self-center "
      >
        <b-col lg="7" md="12" class="align-self-center">
          <div class="sign-user_card ">
            <div class="sign-in-page-data ">
              <div class="sign-in-from w-100 m-auto">
                <sign-up1-form></sign-up1-form>
              </div>
            </div>
            <div class="mt-3">
              <div class="d-flex justify-content-center links textStyle">
                Already have an account?
                <router-link to="/auth/login" class="linkStyle ml-2">
                  Sign in
                </router-link>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import SignUp1Form from './SignUp1Form'

export default {
  name: 'Register',
  components: { SignUp1Form }
}
</script>

<style scoped>
.textStyle {
  color: honeydew;
}
label {
  color: white !important;
}
</style>
