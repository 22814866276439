<template>
 <div class="container h-100">
    <div class="row justify-content-center align-items-center h-100">
      <div class="col-md-6 col-sm-12 col-12 ">
        <div class="sign-user_card ">
          <div class="sign-in-page-data">
            <div class="sign-in-from w-100 m-auto">
              <img src="../../assets/images/login/mail.png" width="80"  alt="">
                <h3 v-if="success" class="mt-3 mb-0">Success !</h3>
                <h3 v-else class="mt-3 mb-0">Error</h3>
                <p v-if="success" class="text-white">Your account has been confirmed.</p>
                <p v-else class="text-white">Please try again or contact with support.</p>
              <div class="d-inline-block w-100">
                  <router-link to="/auth/login" class="btn btn-hover mt-3 buttonStyle">Back to Login</router-link>
              </div>
            </div>
            </div>
        </div>
      </div>
      </div>
  </div>
</template>
<script>
import auth from '../../services/auth'
export default {
  name: 'Confirm',
  data: () => ({
    success: false
  }),
  async beforeMount () {
    const email = this.$route.query.email
    const result = await auth.confirmEmail(email)
    if (result && result.toLowerCase() === 'success') {
      this.$router.push('/auth/login')
    }
  }
}
</script>

<style scoped>
  .buttonStyle{
    background-color: #bf000a;
    color: honeydew;
  }
</style>
