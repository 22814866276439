<template>
    <router-link v-if="urlExists" :to="getUrl" @mouseover.native="onHover" class="iq-sub-card">
      <div class="media align-items-center">
        <img
          :src="getImage"
          class="img-fluid mr-3"
          alt="iamgo"
        />
        <div class="media-body">
          <h6 class="mb-0 ">{{getTitle}}</h6>
          <small class="font-size-12"> {{getDescription}}</small>
        </div>
      </div>
    </router-link>
    <a v-else @mouseover="onHover" class="iq-sub-card">
      <div class="media align-items-center">
        <img
          :src="getImage"
          class="img-fluid mr-3"
          alt="iamgo"
        />
        <div class="media-body">
          <h6 class="mb-0 ">{{getTitle}}</h6>
          <small class="font-size-12"> {{getDescription}}</small>
        </div>
      </div>
    </a>
</template>
<script>

export default {
  name: 'Notification',
  props: ['el'],
  methods: {
    onHover (e) {
      this.$emit('read', this.getUid)
    }
  },
  computed: {
    getUid () {
      return this.el.uid
    },
    getImage () {
      return this.el.image
    },
    getTitle () {
      return this.el.title
    },
    getDescription () {
      return this.el.description
    },
    getType () {
      return this.el.type
    },
    getApplication () {
      return this.el.application
    },
    getUrl () {
      return this.el.url
    },
    urlExists () {
      return this.el.url.length > 0
    }
  }
}

</script>
