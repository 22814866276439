<template>
  <section v-if="sliderData.length > 0" id="iq-trending" class="s-margin">
    <b-container fluid style="min-height: 600px">
        <b-row>
          <b-col sm="12" class=" overflow-hidden">
              <div class="iq-main-header d-flex align-items-center justify-content-between">
                <h4 class="main-title">{{$t('shows.main-heading')}}</h4>
              </div>
              <div class="trending-contens">
                <TrendingNav :currentWidth='currentWidth' @afterChange="afterChange" style="margin-bottom:80px;" v-if="sliderData.length > 1" :sliderData="sliderData" :key="sliderData.length.toString()" />
                <TrendingDetail  style="margin-top:40px;" :detail="detail" :key="selected.toString()"/>
              </div>
          </b-col>
        </b-row>
    </b-container>
  </section>
</template>
<script>
import videoList from '../../../../services/videoList'
import TrendingDetail from './TrendingDetail'
import TrendingNav from './TrendingNav'

export default {
  name: 'Details',
  components: {
    TrendingDetail,
    TrendingNav
  },
  props: ['series', 'currentWidth'],
  mounted () {
    this.currentSeason = 0
    setTimeout(() => {
      var tabEl = document.getElementsByClassName('tranding-tab-nav')
      Array.from(tabEl).map((elem) => {
        elem.addEventListener('shown.bs.tab', function (e) {
          e.target.classList.add('show')
          e.relatedTarget.classList.remove('show')
        })
      })
    }, 2000)
  },
  methods: {
    async addFavorite (uid) {
      const res = await videoList.postAddFavorite(uid)
      if (res.result === 'success') {
        // this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: true } } else { return h } })
        if (this.video.uid === uid) {
          this.video.isFavorite = true
        }
        this.$emit('addFavorite', uid)
      }
    },
    async removeFavorite (uid) {
      const res = await videoList.postRemoveFavorite(uid)
      if (res.result === 'success') {
        // this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: false } } else { return h } })
        if (this.video.uid === uid) {
          this.video.isFavorite = false
        }
        this.$emit('removeFavorite', uid)
      }
    },
    watchVideo (id) {
      return (`/${this.$i18n.locale}/watch?v=` + id)
    },
    suggested (show) {
      return show.seasons && show.seasons[0].videos
    },
    seasonOption (show) {
      return (show && show.seasons && show.seasons.map((s, i) => { return { id: i, value: s.uid, text: this.$t('general.season') + ' ' + (i + 1) } }))
    },
    afterChange (c) {
      this.selected = c
    }
  },
  data () {
    return {
      selected: 0
    }
  },
  computed: {
    sliderData () {
      const arr = this.series || []
      return arr
    },
    sliderDetails () {
      return this.series || []
    },
    detail () {
      return this.series[this.selected] || {}
    }
  }
}
</script>

<style scoped>
.slick-slide.slick-cloned{
  width:21vw;
}
.slick-track{
  width:90vw!important;
  min-height: 100vh!important;
}
.slider-detail{
  width:90vw!important;
  min-height: 100vh!important;
}
</style>
