<template>
<footer id="contact" class="footer-one iq-bg-dark">

        <!-- Address -->
        <div class="footer-top">
            <div class="container-fluid">
                <div class="row footer-standard">
                    <div class="col-lg-7">
                        <div class="widget text-left">
                            <div class="menu-footer-link-1-container">
                                <img src="../../../../assets/images/logo-full.png" style="width:20%;height:20%; margin-bottom:10px" class="c-logo" alt="iamgo">
                                <ul id="menu-footer-link-1" class="menu p-0">
                                    <li id="menu-item-7314" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7314">
                                        <a href="https://blog.iamgo.tv/terms">{{$t('terms.title')}}</a>
                                    </li>
                                    <li id="menu-item-7316" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7316">
                                        <router-link :to="`/${$i18n.locale}/policy`">{{$t('privacy.title')}}</router-link>
                                    </li>

                                    <!-- <li id="menu-item-7315" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-7315">
                                        <router-link :to="`/${$i18n.locale}/Faq`>{{$t('faq.title')}}</router-link>
                                    </li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="widget text-left">
                            <div class="textwidget">
                                <p><small>{{$t('footer.rights')}}</small></p>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-2 col-md-6 mt-4 mt-lg-0">
                        <h6 class="footer-link-title">
                            {{$t('footer.followus')}}
                        </h6>
                        <ul class="info-share">
                            <li><a target="_blank" href="#"><i class="ri-facebook-fill"></i></a></li>
                            <li><a target="_blank" href="#"><i class="fab fa-twitter"></i></a></li>
                            <li><a target="_blank" href="#"><i class="fab fa-google-plus-g"></i></a></li>
                            <li><a target="_blank" href="#"><i class="fab fa-github"></i></a></li>
                        </ul>
                    </div> -->
                    <div  v-if="!isCurrentWidthSmall" class="col-lg-3 col-md-6 mt-4 mt-lg-0">
                        <div class="widget text-left">
                            <div class="textwidget">
                                <h6 class="footer-link-title">{{$t('footer.app')}}</h6>
                                <div class="d-flex align-items-center">
                                    <a class="app-image" href="https://play.google.com/store/apps/details?id=tv.iamgo.app">
                                        <img src="../../../../assets/images/frontend/footer/01.jpg" alt="play-store">
                                    </a><br>
                                    <a style="display:none;" class="ml-3 app-image" href="https://blog.iamgo.tv/appstore"><img src="../../../../assets/images/frontend/footer/02.jpg" alt="app-store"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Address END -->
    </footer>
</template>
<script>

export default {
  name: 'Footer',
  data () {
    return {
      windowWidth: window.innerWidth
    }
  },
  methods: {
    onResize () {
      this.windowWidth = window.innerWidth
    }
  },
  components: {
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  computed: {
    isCurrentWidthSmall () {
      return this.windowWidth < 992
    }
  }
}
</script>
