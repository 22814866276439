<template>
      <section class="iq-main-slider p-0">
      <Slick id="tvshows-slider" :option="sliderOption">
         <div v-for="(item,index) in videoListData" :key="index">
            <router-link :to="watchVideo(item.uid)">
               <div class="shows-img">
                  <img :src="item.image" class="w-100" alt="">
                  <div class="shows-content">
                     <h4 class="text-white mb-1">{{(item.contentType === 'VideoSerie'? item.serieTitle + ' - ' + $t('general.season-abbr') + (item.seasonNumber ? item.seasonNumber: '') + ' - ' + $t('general.episode-abbr') + (item.episodeNumber ? item.episodeNumber: ''): '')}}</h4>
                      <p > <router-link style="fontWeight:bold;font-size:22px;" :to="watchVideo(item.uid)">{{substringTitle(item.title.toUpperCase())}} </router-link></p>
                     <div class="movie-time d-flex align-items-center">
                        <div class="badge badge-secondary p-1 mr-2">{{item.age}}</div>
                        <span class="text-white">{{item.series}}</span>
                     </div>
                  </div>
               </div>
            </router-link>
         </div>
      </Slick>
   </section>
</template>
<script>

export default {
  name: 'Slider',
  components: {
  },
  props: ['highlights'],
  mounted () {
  },
  data () {
    return {
      sliderOption: {
        centerMode: true,
        centerPadding: '200px',
        slidesToShow: 1,
        nextArrow: '<button class="NextArrow"><i class="ri-arrow-right-s-line"></i></button>',
        prevArrow: '<button class="PreArrow"><i class="ri-arrow-left-s-line"></i></button>',
        arrows: true,
        dots: false,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 1
            }
          }
        ]
      }

    }
  },
  methods: {
    watchVideo (id) {
      return (`/${this.$i18n.locale}/watch?v=` + id)
    },
    substringTitle (title) {
      if (title && title.length > 60) {
        return title.substring(0, 60) + '...'
      } else {
        return title
      }
    }
  },
  computed: {
    videoListData () {
      return this.highlights.map(l => { return { ...l, image: l.thumbCoverImage, time: l.duration } })
    }
  }
}
</script>
