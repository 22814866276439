
import MainPage from './page/private/MainPage'
import Faq from './page/private/Faq'
import Library from './page/private/Library'
import MostViewed from './page/private/MostViewed'
import Favorites from './page/private/Favorites'
import MyList from './page/private/MyList'
import Search from './page/private/Search'
import Promo from './page/private/Promo'
import Tutorial from './page/private/Tutorial'
import News from './page/private/News'
import Motivation from './page/private/Motivation'
import PrivacyPolicy from './page/private/PrivacyPolicy'
import Settings from './page/private/Settings'
import Checkout from './page/private/Checkout'
import PricingRoom from './page/private/PricingRoom'
import Player from './page/private/Player'

export default [
  {
    path: '',
    meta: { auth: true },
    component: MainPage
  },
  {
    path: 'Faq',
    meta: { auth: true },
    component: Faq
  },
  {
    path: 'policy',
    meta: { auth: true },
    component: PrivacyPolicy
  },
  {
    path: 'library',
    meta: { auth: true },
    component: Library
  },
  {
    path: 'mostviewed',
    meta: { auth: true },
    component: MostViewed
  },
  {
    path: 'favorites',
    meta: { auth: true },
    component: Favorites
  },
  {
    path: 'mylist',
    meta: { auth: true },
    component: MyList
  },
  {
    path: 'search',
    meta: { auth: true },
    component: Search
  },
  {
    path: 'promo',
    meta: { auth: true },
    component: Promo
  },
  {
    path: 'tutorial',
    meta: { auth: true },
    component: Tutorial
  },
  {
    path: 'news',
    meta: { auth: true },
    component: News
  },
  {
    path: 'motivation',
    meta: { auth: true },
    component: Motivation
  },
  {
    path: 'settings',
    meta: { auth: true },
    component: Settings
  },
  {
    path: 'checkout',
    meta: { auth: true },
    component: Checkout
  },
  {
    path: 'pricing',
    meta: { auth: true },
    component: PricingRoom
  },
  {
    path: 'watch',
    meta: { auth: true },
    component: Player
  }
]
