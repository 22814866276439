<template>
 <section id="iq-library">
  <b-container fluid>
    <h6 v-if="errorMessage.length" class="" style="margin-bottom: 60px;margin-left: auto;margin-right:auto;">{{errorMessage}}</h6>
    <b-row v-else class="video-container row" style="height:100%; width:100%;margin-left: auto;margin-right:auto;">
      <div id="video-container" class="col-md-12 embed-responsive embed-responsive-16by9" style=""></div>
    </b-row>
    <b-row class="description-container row" style="margin-top: 20px">
      <b-col lg="12">
          <div class="trending-info season-info g-border">
              <div class="d-flex align-items-center text-white text-detail episode-name mb-0 row">
                  <div class="col">
                    <h4 v-if="showName.length > 0" class="trending-text big-title text-uppercase mt-0" style="line-height:20px!important">{{showName}}</h4>
                    <span v-if="videoSeasonAndEpisode.length > 0" :class="'trending-year'" style="padding-left:0px!important">{{videoSeasonAndEpisode}}</span>
                    <span :class="'trending-year' + (video.contentType === 'VideoSerie' ?' before':'')" :style="video.contentType !== 'VideoSerie'?'padding-left:0px;':''">{{video.title}}</span>
                  </div>
              </div>
              <div class="row row-mobile">
                <div class="trending-dec w-100 mb-0 col" style="padding-bottom: 20px" v-html="video.shortDescription"></div>
                <div class="d-flex align-items-center col text-white text-detail episode-name mb-0 row">
                  <div class="col" v-if="video.contentType === 'VideoSerie'">
                    <ul class="col list-inline p-0 share-icons music-play-lists align-self-end small-screen" style="display:none;" >
                      <li style="display:none;" @click="toggleFavorite(video.isFavorite, video.uid, $event)"><span><i v-if="!video.isFavorite" class="ri-heart-line"></i><i v-else class="ri-heart-fill"></i></span></li>
                      <li style="display:none;" @click="toggleMyList(video.isMyList, video.uid, $event)"><span :class="!video.isMyList?'':'fill-background'"><i v-if="!video.isMyList" class="ri-add-line"></i><i v-else class="ri-add-line fill-background"></i></span></li>
                    </ul>
                    <div class="col align-self-start trending-list" style='margin-left:10px'>
                      <div class="hover-buttons">
                        <b-button v-b-modal.modal-7
                          class="btn btn-hover"
                          ><i class="ri-share-forward-line font-size-24"></i>{{ $t("general.share") }}</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row row-mobile" v-if="video.contentType !== 'VideoSerie'">
                <div class="col align-self-start trending-list mt-4" style='margin-left:10px'>
                  <div class="text-primary title">{{$t('video.author')}}: <span class="text-body">{{video.author}}</span>
                  </div>
                  <div class="text-primary title">{{$t('video.genres')}}: <span class="text-body">{{video.genre}}</span>
                  </div>
                  <div class="text-primary title">{{$t('video.tags')}}: <span class="text-body">{{videoTags}}</span>
                  </div>
                </div>
                <div class="col align-self-start trending-list" style='margin-left:10px'>
                  <div class="hover-buttons">
                    <b-button v-b-modal.modal-7
                      class="btn btn-hover"
                      ><i class="ri-share-forward-line font-size-24"></i>{{ $t("general.share") }}</b-button
                    >
                  </div>
                </div>
                <ul class="col list-inline p-0 mt-4 share-icons music-play-lists align-self-end" style='margin-left:10px'>
                  <li style="display:none;" @click="toggleFavorite(video.isFavorite, video.uid, $event)"><span><i v-if="!video.isFavorite" class="ri-heart-line"></i><i v-else class="ri-heart-fill"></i></span></li>
                  <li style="display:none;" @click="toggleMyList(video.isMyList, video.uid, $event)"><span :class="!video.isMyList?'':'fill-background'"><i v-if="!video.isMyList" class="ri-add-line"></i><i v-else class="ri-add-line fill-background"></i></span></li>
                </ul>
              </div>
          </div>
      </b-col>
    </b-row >

    <div class="row iq-custom-select d-inline-block sea-epi pull-right" style="padding-top:50px;margin-left:10px" v-if="video.contentType === 'VideoSerie'">
      <V-select style="right:0" :options="seasonOption" v-model="selected">
      </V-select>
    </div>
    <div class=""  v-if="video.contentType === 'VideoSerie'">
      <div class="trending-dec" style="padding-left:20px" v-html="video.seasons[selected] && video.seasons[(selected)].seasonDescription"></div>
      <h4 class="main-title mb-4" style="margin-top:50px;margin-left:10px">{{$t('video.show-heading')}}</h4>

    </div>

    <b-row  class="library-container" v-if="video.contentType === 'VideoSerie'">
      <li class="slide-item row" v-for="(item,index) in videoListData" :key="index">

            <div class="block-images position-relative">
              <div class="img-box">
                  <img :src="item.image" class="img-fluid" alt="">
              </div>
              <div class="block-description" style="margin-top:20px;margin-bottom:20px;">
                <span v-if="item.contentType === 'VideoSerie'" style="margin-bottom:0px;margin-top:auto;font-size:12px;"> <router-link :to="watchVideo(item.uid)">{{(item.contentType === 'VideoSerie'? substringSeason(item.serieTitle) + ' - ' + $t('general.season-abbr') + (item.seasonNumber ? item.seasonNumber: '') + ' - ' + $t('general.episode-abbr') + (item.episodeNumber ? item.episodeNumber: ''): '')}} </router-link></span>
                <p :style="'margin-bottom:0px;' + (item.contentType !== 'VideoSerie'?'margin-top:auto;':'')"> <router-link style="fontWeight:bold;font-size:16px;" :to="watchVideo(item.uid)">{{substringTitle(item.title.toUpperCase())}} </router-link></p>
                <div style="display:flex;gap:20px;">
                  <div class="hover-buttons">
                    <span @click="watchVideoSetUrl(item.uid)" class="btn btn-hover"><i class="fa fa-play mr-1" aria-hidden="true"></i>
                    {{$t('general.play-now')}}</span>
                  </div>
                  <div class="movie-time d-flex align-items-center my-2">
                    <span class="text-white" v-if="!(item.contentType === 'VideoSerie' || item.serieType === 'Serie')">{{timeToString(item.time)}} min</span><span class="text-white" v-else >{{item.seasonNumber}} {{$t('general.seasons')}}</span>
                  </div>
                </div>
              </div>
              <div class="block-social-info">
                  <ul class="list-inline p-0 m-0 music-play-lists">
                    <li style="display:none;" @click="toggleFavorite(item.isFavorite, item.uid, $event)"><span><i v-if="!item.isFavorite" class="ri-heart-line"></i><i v-else class="ri-heart-fill"></i></span></li>
                    <li style="display:none;" @click="toggleMyList(item.isMyList, item.uid, $event)"><span :class="!item.isMyList?'':'fill-background'"><i v-if="!item.isMyList" class="ri-add-line"></i><i v-else class="ri-add-line fill-background"></i></span></li>
                  </ul>
              </div>
            </div>
      </li>
    </b-row>
  </b-container>
  <Suggested v-if="video.contentType !== 'VideoSerie'" :suggested="suggested" @addFavorite="addFavorite" @removeFavorite="removeFavorite" />
<b-modal id="modal-7" centered :title="$t('general.share')" hide-footer>
  <div style="display:flex;justify-content:center;">
    <button style="border-radius:10px;margin:10px;background-color:#4267B2">
      <i style="cursor:pointer;" class="ri-facebook-fill font-size-40" @click="copyUrlToFacebook"></i>
    </button>
    <button style="border-radius:10px;margin:10px;background-color:#1DA1F2">
      <i style="cursor:pointer;" class="ri-twitter-fill font-size-40" @click="copyUrlToTwitter"></i>
    </button>
    <button style="border-radius:10px;margin:10px;background-color:#25D366">
      <i style="cursor:pointer;" class="ri-whatsapp-fill font-size-40" @click="copyUrlToWhatsapp"></i>
    </button>
    <button style="border-radius:10px;margin:10px;background-color:white">
      <i style="cursor:pointer;" class="ri-mail-fill font-size-40" @click="copyUrlToEmail"></i>
    </button>
  </div>

  <input
    id="url-input"
    type="text"
    class="text search-input font-size-12"
    v-model="currentUrl"
    @keydown="cancelEvent($event)"
    style="padding-right:40px;margin-top:20px;"
  /><i style="margin-left: -30px;cursor:pointer;" class="ri-file-copy-line font-size-24" @click="copyUrl"></i>
</b-modal>
</section>
</template>
<script>
import videoList from '../../services/videoList'
import Suggested from '../../views/FrontendPages/Components/Suggested/Suggestion'
import Player from '@vimeo/player'
import { core } from '../../config/pluginInit'

export default {
  name: 'Player',
  components: {
    Suggested
  },
  async beforeMount () {
    const res = await videoList.getVideoToPlay(this.$route.query.v)
    if (res.result === 'success') {
      this.video = res.video_list
    }
    const calls = [videoList.getSuggestedListByVideoUid(this.video.uid), videoList.getListSeason(this.video.seasonUID)]
    const responses = await Promise.all(calls)
    if (responses[0].result === 'success') {
      this.suggested = responses[0].video_list
    }
    if (responses[1].result === 'success') {
      this.library = responses[1].video_list
    }
    const ress = { result: 'success', title: 'Show Name' } // get Show name
    if (ress.result === 'success') {
      this.showTitle = ress.title
    }
    if (this.video.seasons) {
      this.selected = this.video.seasons.findIndex((s) => s.uid === this.video.seasonUID)
      this.selectedSeasonId = this.video.seasonUID
    }
    const player = new Player('video-container', {
      url: this.video.videoURL,
      playsinline: false,
      autoplay: true
    })
    player.getVideoId().then((id) => {
    }).catch((e) => {
      this.errorMessage = 'Erro, vídeo não pode ser executado'
    })
    player.on('ended', () => {
      if (this.video.nextEpisodeUID.length > 0) {
        this.$router.push(`/${this.$i18n.locale}/watch?v=` + this.video.nextEpisodeUID)
      }
    })
    player.on('loaded', () => {
      player.play()
    })
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      library: [],
      suggested: [],
      video: {},
      errorMessage: '',
      showTitle: '',
      selected: 0,
      selectedSeasonId: '',
      currentUrl: window.location.href
    }
  },
  methods: {
    async addFavorite (uid) {
      // toggle no status do vídeo
      this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: true } } else { return h } })
      if (this.video.uid === uid) {
        this.video.isFavorite = true
      }
      this.$emit('addFavorite', uid)
    },
    async removeFavorite (uid) {
      // toggle no status do vídeo
      this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: false } } else { return h } })
      if (this.video.uid === uid) {
        this.video.isFavorite = false
      }
      this.$emit('removeFavorite', uid)
    },
    async addMyList (uid) {
      // toggle no status do vídeo
      this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isMyList: true } } else { return h } })
      if (this.video.uid === uid) {
        this.video.isMyList = true
      }
      this.$emit('addMyList', uid)
    },
    async removeMyList (uid) {
      this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isMyList: false } } else { return h } })
      if (this.video.uid === uid) {
        this.video.isMyList = false
      }
      this.$emit('removeMyList', uid)
    },
    async toggleFavorite (isFavorite, uid, e) {
      e.stopPropagation()
      if (isFavorite) {
        const res = await videoList.postRemoveFavorite(uid)
        if (res.result === 'success') {
          this.removeFavorite(uid)
        }
      } else {
        const res = await videoList.postAddFavorite(uid)
        if (res.result === 'success') {
          this.addFavorite(uid)
        }
      }
    },
    async toggleMyList (isMyList, uid, e) {
      e.stopPropagation()
      if (isMyList) {
        const res = await videoList.postRemoveMyList(uid)
        if (res.result === 'success') {
          this.removeMyList(uid)
        }
      } else {
        const res = await videoList.postAddMyList(uid)
        if (res.result === 'success') {
          this.addMyList(uid)
        }
      }
    },
    watchVideoSetUrl (id) {
      this.$router.push(`/${this.$i18n.locale}/watch?v=` + id)
      return ''
    },
    watchVideo (id) {
      return (`/${this.$i18n.locale}/watch?v=` + id)
    },
    substringSeason (title) {
      if (title && title.length > 30) {
        return title.substring(0, 30) + '...'
      } else {
        return title
      }
    },
    substringTitle (title) {
      if (title && title.length > 60) {
        return title.substring(0, 60) + '...'
      } else {
        return title
      }
    },
    timeToString (segs) {
      if (segs <= 59) {
        return `${segs} seg`
      }
      return `${(segs / 60).toFixed(0)} min ${segs % 60 !== 0 ? (segs % 60).toString() + ' segs' : ''}`
    },
    cancelEvent (e) {
      e.preventDefault()
    },
    copyUrl () {
      navigator.clipboard.writeText(window.location.href)
      document.getElementById('url-input').select()
    },
    copyUrlToFacebook () {
      const url = 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.href
      window.open(url, '_blank').focus()
    },
    copyUrlToTwitter () {
      const url = 'https://twitter.com/intent/tweet?url=' + window.location.href
      window.open(url, '_blank').focus()
    },
    copyUrlToWhatsapp () {
      const url = 'https://api.whatsapp.com/send/?text=' + window.location.href
      window.open(url, '_blank').focus()
    },
    copyUrlToEmail () {
      const url = window.location.href
      window.open('mailto:test@example.com?subject=Iamgo&body=' + url)
    }
  },
  computed: {
    videoListData () {
      return this.library.map(l => { return { ...l, image: l.thumbCoverImage, time: l.duration } })
    },
    videoSeasonAndEpisode () {
      return (this.video.seasons && this.video.seasons.length > 0) ? ('S' + this.video.seasonNumber + 'E' + this.video.episodeNumber) : ''
    },
    videoTags () {
      return this.video.tags && this.video.tags.join(', ')
    },
    showName () {
      return (this.video.seasons && this.video.seasons.length > 0) ? this.video.serieTitle : ''
    },
    seasonOption () {
      return (this.video.seasons && this.video.seasons.map((s, i) => { return { id: i, value: s.uid, text: this.$t('general.season') + ' ' + (i + 1) } }))
    }
  },
  watch: {
    async selected (newSelectedId) {
      const newSeasonId = this.video.seasons[newSelectedId].uid
      const videoArray = await videoList.getListSeason(newSeasonId)
      this.library = videoArray.video_list
    }
  }
}
</script>
<style scoped>
.trending-info.g-border {
    border-image-source: linear-gradient(to left, rgba(209, 208, 207, 0), rgba(209, 208, 207, 0.6), rgba(209, 208, 207, 0));
}
.trending-info {
    padding: 0 0 20px 0;
    border: 1px solid;
        border-top-width: 0px;
        border-right-width: 0px;
        border-bottom-width: 1px;
        border-left-width: 0px;
        border-image-slice: 100%;
        border-image-source: none;
    border-image-slice: 1;
    border-width: 0 0 1px 0;
}
li {
  list-style-type:none;
}

.library-container {
  min-height: 650px;
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 2rem;
}

@media (min-width:356px) {
  .library-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width:768px) {
  .library-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width:1200px) {
  .library-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width:1300px) {
  .video-container {
    max-width: 70vw;
  }
}
@media (min-width:1500px) {
  .library-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (max-width:700px) {

.row-mobile {
  display: flex!important;
  flex-direction: column!important;
}
}
</style>
