<template>
  <div
  >
    <b-container>
      <b-row
        class="justify-content-center align-items-center height-self-center"
      >
        <b-col lg="5" md="12" class="align-self-center">
          <div class="sign-user_card ">
            <div class="sign-in-page-data">
              <div class="sign-in-from w-100 m-auto">
                <h3 class="mb-3 text-center">Sign in</h3>
                <sign-in1-form
                  formType="jwt"
                  :email="defaultEmail"
                  :password="defaultPass"
                ></sign-in1-form>
              </div>
            </div>
            <div class="mt-3">
              <div class="d-flex justify-content-center links textStyle">
                Don't have an account?
                <router-link to="/auth/register" class="linkStyle ml-2">
                  Sign Up
                </router-link>
              </div>
              <div class="d-flex justify-content-center links">
                <router-link to="/auth/recover" class="f-link"
                  >Forgot your password?</router-link
                >
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SignIn1Form from './SignIn1Form'

export default {
  name: 'SignIn1',
  components: { SignIn1Form },
  data: () => ({
    defaultEmail: '',
    defaultPass: ''
  }),
  beforeMount () {
    const tempE = localStorage.getItem('remE')
    const tempP = localStorage.getItem('remP')

    if (tempE && tempP) {
      this.defaultEmail = tempE
      this.defaultPass = tempP
    }
  },
  mounted () {
    const loggedIn = localStorage.getItem('token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'client' })
    }
  },
  methods: {}
}
</script>

<style scoped>
.textStyle {
  color: honeydew;
}
</style>
