<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <div class="alert alert-danger" v-if="message" role="alert">
      <span >{{ message }}</span>
    </div>
    <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider vid="email" name="E-mail" rules="required|email" v-slot="{ errors }">
        <div class="form-group emailStyle">
          <input type="email" :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="emailInput" aria-describedby="emailHelp"
                 v-model="user.email" placeholder="Enter email" required>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider vid="password" name="Password" rules="required" v-slot="{ errors }">
        <div class="form-group passwordStyle">
          <i :class="(showPass?'ri-eye-line ri-xl red':'ri-eye-line ri-xl ')" id="togglePassword" @click="togglePassword"></i>
          <input :type="showPass?'text':'password'"  :class="'form-control mb-0' +(errors.length > 0 ? ' is-invalid' : '')"
                 id="passwordInput"
                 v-model="user.password" placeholder="Password" required />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <div style="text-align:center;">
      </div>
      <div class="sign-info">
         <button type="submit" @click="recaptcha" class="btn btn-hover buttonStyle textStyle">
           <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>Sign in</button>
          <div class="custom-control custom-checkbox d-inline-block ">
            <input type="checkbox" class="custom-control-input" v-model="checkRemember" :id="formType">
            <label class="custom-control-label" :for="formType">Remember Me</label>
          </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex'
import auth from '../../services/auth'

export default {
  name: 'SignIn1Form',
  props: ['formType', 'email', 'password'],
  data: () => ({
    user: {
      email: '',
      password: ''
    },
    message: undefined,
    checkRemember: false,
    loading: false,
    showPass: false
  }),
  beforeMount () {
    if (this.email && this.password) {
      this.checkRemember = true
    }
  },
  mounted () {
    this.user.email = this.$props.email
    this.user.password = this.$props.password
  },
  computed: {
    ...mapGetters({
      getToken: 'getToken'
    })
  },

  methods: {
    async recaptcha (e) {
      // (optional) Wait until recaptcha has been loaded.
      e.preventDefault()
      let fullpath
      this.loading = true
      // eslint-disable-next-line
      await grecaptcha.ready(() => {
        // eslint-disable-next-line
        grecaptcha.execute('6LfMA64aAAAAAFByMyPhKQJMynWvuqyTnH_ro57I', { action: 'login' }).then(async (gtoken) => {
          // Do stuff with the received token.
          const { result, token, refreshToken } = await auth.login(this.user.email, this.user.password, gtoken)
          switch (result) {
            case 'authenticated':
              localStorage.setItem('token', token)
              localStorage.setItem('refreshToken', refreshToken)
              if (this.checkRemember) {
                localStorage.setItem('remE', this.user.email)
                localStorage.setItem('remP', this.user.password)
              } else {
                localStorage.removeItem('remE')
                localStorage.removeItem('remP')
              }
              // go to private home
              try {
                fullpath = localStorage.getItem('goTo')
              } catch (error) {
              }
              fullpath = fullpath || '/'
              this.$router.push(fullpath)
              break
            case 'user_or_pass_error':
              this.message = 'Invalid User/Password'
              break
            case 'blocked_user':
              this.message = 'Invalid Name/Surname'
              break
            case 'invalid_captcha':
              this.message = 'Invalid Captcha'
              break
            case 'email_notConfirmed':
              this.message = 'Email not confirmed'
              break
            default:
              this.message = 'Error. Contact us if the error persists.'
              break
          }
          this.loading = false
        })
      })
    },
    togglePassword () {
      this.showPass = !this.showPass
    }
  }
}
</script>
<style scoped>
  input{
    background-color: rgba(65, 65, 65, 0.480);
  }
  .buttonStyle{
    background-color: #bf000a;
  }
  .textStyle{
    color: honeydew;
  }
  label {
    color: white!important;
  }
  .ri-eye-line {
    cursor: pointer;
    position: absolute;
    z-index: 999;
    height:2em;
    right: 0;
    padding:15px;
    margin-right:30px;
  }
  .red {
    color: #bf000a
  }
</style>
