<template>
 <section id="iq-favorite">
  <b-container fluid>
  <h4 class="main-title mb-4">{{$t('mylist.main-heading')}}</h4>
  <b-row  v-if="videoListData && videoListData.length > 0" class="favorite-container">
    <li class="slide-item" v-for="(item,index) in videoListData" :key="index" @click="watchVideoSetUrl(item.uid)" style="cursor:pointer">

          <div class="block-images position-relative">
            <div class="img-box">
                <img :src="item.image" class="img-fluid" alt="">
            </div>
              <div class="block-description" style="margin-top:20px;margin-bottom:20px;">
                <span v-if="item.contentType === 'VideoSerie'" style="margin-bottom:0px;margin-top:auto;font-size:12px;"> <router-link :to="watchVideo(item.uid)">{{(item.contentType === 'VideoSerie'? substringSeason(item.serieTitle) + ' - ' + $t('general.season-abbr') + (item.seasonNumber ? item.seasonNumber: '') + ' - ' + $t('general.episode-abbr') + (item.episodeNumber ? item.episodeNumber: ''): '')}} </router-link></span>
                <p :style="'margin-bottom:0px;' + (item.contentType !== 'VideoSerie'?'margin-top:auto;':'')"> <router-link style="fontWeight:bold;font-size:16px;" :to="watchVideo(item.uid)">{{substringTitle(item.title.toUpperCase())}} </router-link></p>
                <div style="display:flex;gap:20px;">
                  <div class="hover-buttons">
                    <span @click="watchVideoSetUrl(item.uid)" class="btn btn-hover"><i class="fa fa-play mr-1" aria-hidden="true"></i>
                    {{$t('general.play-now')}}</span>
                  </div>
                  <div class="movie-time d-flex align-items-center my-2">
                    <span class="text-white" v-if="!(item.contentType === 'VideoSerie' || item.serieType === 'Serie')">{{timeToString(item.time)}} min</span><span class="text-white" v-else >{{item.seasonNumber}} {{$t('general.seasons')}}</span>
                  </div>
                </div>
            </div>
            <div class="block-social-info">
                <ul class="list-inline p-0 m-0 music-play-lists">
                  <!-- <li class="share">
                                <span><i class="ri-share-fill"></i></span>
                                <div class="share-box">
                                    <div class="d-flex align-items-center">
                                      <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i class="ri-facebook-fill"></i></a>
                                      <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i class="ri-twitter-fill"></i></a>
                                      <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                    </div>
                                </div>
                              </li> -->
                  <li style="display:none;" @click="toggleFavorite(item.isFavorite, item.uid, $event)"><span><i v-if="!item.isFavorite" class="ri-heart-line"></i><i v-else class="ri-heart-fill"></i></span></li>
                  <li style="display:none;" @click="toggleMyList(item.isMyList, item.uid, $event)"><span :class="!item.isMyList?'':'fill-background'"><i v-if="!item.isMyList" class="ri-add-line"></i><i v-else class="ri-add-line fill-background"></i></span></li>
                </ul>
            </div>
          </div>
    </li>
  </b-row>
  <div  v-else class="container row" >
      <div class="col-md-12">
        <p style="margin-top:150px;margin-bottom:100px;text-align:center;color:#C0C0C0;width:100%">{{$t('mylist.not-found')}}</p>
      </div>
  </div>
  </b-container>
</section>
</template>
<script>
import videoList from '../../services/videoList'

export default {
  name: 'MyList',
  components: {
  },
  async beforeMount () {
    const response = await videoList.getAllMyList()
    if (response.result === 'success') {
      this.myList = response.video_list
    }
  },
  data () {
    return {
      myList: []
    }
  },
  methods: {
    async toggleFavorite (isMyList, uid, e) {
      e.stopPropagation()
      if (isMyList) {
        const res = await videoList.postRemoveFavorite(uid)
        if (res.result === 'success') {
          this.myList = this.myList.map((el) => { if (el.uid === uid) { return { ...el, isFavorite: false } } else { return el } })
        }
      } else {
        const res = await videoList.postAddFavorite(uid)
        if (res.result === 'success') {
          this.myList = this.myList.map((el) => { if (el.uid === uid) { return { ...el, isFavorite: true } } else { return el } })
        }
      }
    },
    async toggleMyList (isMyList, uid, e) {
      e.stopPropagation()
      if (isMyList) {
        const res = await videoList.postRemoveMyList(uid)
        if (res.result === 'success') {
          this.myList = this.myList.filter((el) => (el.uid !== uid))
        }
      } else {
        const res = await videoList.postAddMyList(uid)
        if (res.result === 'success') {
          this.myList = this.myList.map((el) => { if (el.uid === uid) { return { ...el, isMyList: true } } else { return el } })
        }
      }
    },
    watchVideoSetUrl (id) {
      this.$router.push(`/${this.$i18n.locale}/watch?v=` + id)
      return ''
    },
    watchVideo (id) {
      return (`/${this.$i18n.locale}/watch?v=` + id)
    },
    substringSeason (title) {
      if (title && title.length > 30) {
        return title.substring(0, 30) + '...'
      } else {
        return title
      }
    },
    substringTitle (title) {
      if (title && title.length > 60) {
        return title.substring(0, 60) + '...'
      } else {
        return title
      }
    },
    timeToString (segs) {
      if (segs <= 59) {
        return `${segs} seg`
      }
      return `${(segs / 60).toFixed(0)} min ${segs % 60 !== 0 ? (segs % 60).toString() + ' segs' : ''}`
    }
  },
  computed: {
    videoListData () {
      return this.myList.map(l => { return { ...l, image: l.thumbCoverImage, time: l.duration } })
    }
  }
}
</script>
<style scoped>
li {
  list-style-type:none;
}

.favorite-container {
  padding-top: 3em;
  min-height: 650px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: minmax(min-content, max-content);
  grid-gap: 1rem;
}

@media (min-width:356px) {
  .favorite-container {
    grid-template-columns: 1fr;
  }
}

@media (min-width:768px) {
  .favorite-container {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width:990px) {
  .favorite-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 10em;
  }
}
@media (min-width:1200px) {
  .favorite-container {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width:1300px) {
  .favorite-container {
    padding-top: 3em;
  }
}
@media (min-width:1500px) {
  .favorite-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
</style>
