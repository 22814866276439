import axios from './index'
import defaultAxios from 'axios'
import jwtDecode from 'jwt-decode'

const axiosWithTimeout = defaultAxios.create({
  baseURL: 'https://api.iamgo.tv',
  // baseURL: 'https://localhost:44346',
  timeout: 3000
})

const login = async (email, pass, capt) => {
  try {
    const res = await axios.post('/api/accounts/login', { email, password: pass, captcha_token: capt })
    return res.data
  } catch (error) {
    return { result: 'error' }
  }
}

export default {
  async register (fn, ln, email, pass, lang, capt) {
    try {
      const res = await axios.post('/api/accounts/register', { firstName: fn, lastName: ln, email, password: pass, language: lang, captcha_token: capt })
      return res.data.result
    } catch (error) {
      return 'error'
    }
  },
  async resend (email, capt) {
    try {
      const res = await axios.post('/api/Accounts/ResentConfirmationEmail', { email, captcha_token: capt })
      return res.data.result
    } catch (error) {
      return 'error'
    }
  },
  login,
  async recover (email, capt) {
    try {
      const res = await axios.post('/api/accounts/RequestResetPassword', { email, captcha_token: capt })
      return res.data.result
    } catch (error) {
      return 'error'
    }
  },
  async resetPassword (email, newPassword, passToken, capt) {
    try {
      const res = await axios.post('/api/accounts/ResetPassword', { userId: email, newPassword, reset_password_token: passToken, captcha_token: capt })
      return res.data.result
    } catch (error) {
      return 'error'
    }
  },
  async getListLanguages () {
    try {
      const res = await axios.get('/api/Languages/List')
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async refreshToken (refreshToken) {
    try {
      const res = await axios.post('/api/Authentication/RefreshToken', { refreshToken })
      return res.data
    } catch (error) {
      return 'error'
    }
  },
  async confirmEmail (email) {
    try {
      const res = await axios.post('/api/Accounts/ConfirmEmail', { email })
      return res.data.result
    } catch (error) {
      return 'error'
    }
  },
  async refreshTokenWithTimeout (refreshToken) {
    try {
      const res = await axiosWithTimeout.post('/api/Authentication/RefreshToken', { refreshToken })
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async validateToken () {
    const tokenExpiration = localStorage.getItem('lastTokenExpiration')
    if (!tokenExpiration || parseInt(tokenExpiration) <= Date.now()) {
      const refresh = localStorage.getItem('refreshToken')
      const { result, token, refreshToken } = await this.refreshTokenWithTimeout(refresh)
      if (result === 'success') {
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        var decoded = jwtDecode(token)
        localStorage.setItem('lastTokenExpiration', decoded.exp.toString() + '000')
      } else {
        const { result, token, refreshToken } = await login('pibofel242@xasems.com', 'NJ^nzktgV*egz6n8ME@a', '')
        switch (result) {
          case 'authenticated':
            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)
            break
          case 'user_or_pass_error':
            break
          case 'blocked_user':
            break
          case 'invalid_captcha':
            break
          case 'email_notConfirmed':
            break
          default:
            break
        }
      }
    }
  }
}
