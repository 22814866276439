<template>
<section class="m-profile setting-wrapper">
    <b-container>
        <h4 class="main-title mb-4">{{$t('settings.main-heading')}}</h4>
        <b-row>
            <b-col lg="4" class="mb-3">
                <div class="sign-user_card text-center">
                    <img :src="photoUrl" class="rounded-circle img-fluid d-block mx-auto mb-3" alt="user">
                    <h4 class="mb-3">{{name}}</h4>
                    <!-- <a href="#" class="edit-icon text-primary">Edit</a> -->
                </div>
            </b-col>
            <b-col lg="8">
                <div class="sign-user_card">
                    <h5 class="mb-3 pb-3 a-border">{{$t('settings.personal-details')}}</h5>
                    <div class="row align-items-center justify-content-between mb-3">
                        <div class="col-md-8">
                            <span class="text-light font-size-13">{{$t('settings.personal-email')}}</span>
                            <p class="mb-0">{{email}}</p>
                        </div>
                    </div>
                    <div v-if="!changeName" class="row align-items-center justify-content-between mb-3">
                        <div class="col-md-8">
                            <span class="text-light font-size-13">{{$t('settings.personal-name')}}</span>
                            <p class="mb-0">{{name}}</p>
                        </div>
                        <div class="col-md-4 text-md-right text-left">
                            <span class="text-primary" style="cursor:pointer" @click="toggleName">{{$t('settings.change')}}</span>
                        </div>
                    </div>
                    <div v-else class="row align-items-center justify-content-between mb-3">
                        <div class="col-md-8">
                          <div class="form-group">
                            <label style="color: white">{{$t('settings.personal-name')}}</label>
                            <input type="text" class="form-control mb-0 textStyle" id="exampleInputName2" placeholder="Name"  v-model="newName" required>
                          </div>
                        </div>
                        <div class="col-md-8">
                          <div class="form-group">
                            <label style="color: white">{{$t('settings.personal-surname')}}</label>
                            <input type="text" class="form-control mb-0 textStyle" placeholder="Last name"  v-model="newLastName" required>
                          </div>
                        </div>
                        <div class="col-md-2 text-md-right text-left">
                            <span class="text-primary" style="cursor:pointer" @click="toggleName">{{$t('settings.cancel')}}</span>
                        </div>
                        <div class="col-md-2 text-md-right text-left">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>
                            <span class="text-primary" style="cursor:pointer" @click="saveName">{{$t('settings.save')}}</span>
                        </div>
                        <label class="col-md-12 text-center" style="color:white" v-if="nameMessage">{{nameMessage}}</label>
                    </div>
                    <div v-if="!changePassword" class="row align-items-center justify-content-between mb-3">
                        <div class="col-md-8">
                            <span class="text-light font-size-13">{{$t('settings.personal-password')}}</span>
                            <p class="mb-0">**********</p>
                        </div>
                        <div class="col-md-4 text-md-right text-left">
                            <span class="text-primary" style="cursor:pointer" @click="togglePassword">{{$t('settings.change')}}</span>
                        </div>
                    </div>
                    <div v-else class="row align-items-center justify-content-between mb-3">
                      <div class="row mb-3 col-md-12">
                        <div class="col-md-8">
                          <div class="form-group">
                            <label style="color: white">{{$t('settings.personal-current-password')}}</label>
                            <input type="password" class="form-control mb-0 textStyle" id="exampleInputPassword2" :placeholder="$t('settings.personal-current-password')"  v-model="currentPassword" required>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3 col-md-12" style="padding-right:0px!important;">
                        <div class="col-md-4">
                          <div class="form-group">
                            <label style="color: white">{{$t('settings.personal-new-password')}}</label>
                            <input type="password" class="form-control mb-0 textStyle" id="exampleInputPassword2" :placeholder="$t('settings.personal-password')"  v-model="newPassword" required>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label style="color: white">{{$t('settings.personal-repeat-password')}}</label>
                            <input type="password" class="form-control mb-0 textStyle" id="exampleInputPassword2" :placeholder="$t('settings.personal-repeat-password')" v-model="newConfirmPassword" required>
                          </div>
                        </div>
                        <div class="col-md-2 text-md-right text-left" >
                            <span class="text-primary" style="cursor:pointer" @click="togglePassword">{{$t('settings.cancel')}}</span>
                        </div>
                        <div class="col-md-2 text-md-right text-left" style="padding-right:0px!important;">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>
                            <span class="text-primary" style="cursor:pointer" @click="savePassword">{{$t('settings.save')}}</span>
                        </div>
                      </div>
                      <label class="col-md-12 text-center" style="color:white" v-if="passwordMessage">{{passwordMessage}}</label>
                    </div>
                    <div v-if="!changeBirthdate" class="row align-items-center justify-content-between mb-3">
                        <div class="col-md-8">
                            <span class="text-light font-size-13">{{$t('settings.personal-birthdate')}}</span>
                            <p class="mb-0">{{birthdate}}</p>
                        </div>
                        <div class="col-md-4 text-md-right text-left">
                            <span class="text-primary" style="cursor:pointer" @click="toggleBirthdate">{{$t('settings.change')}}</span>
                        </div>
                    </div>
                    <div v-else class="row align-items-center justify-content-between mb-3">
                        <div class="col-md-8">
                          <div class="form-group">
                            <label style="color: white">{{$t('settings.personal-birthdate')}}</label>
                            <input type="text" class="form-control mb-0 textStyle bdate-input" id="bd-input" placeholder="dd/MM/yyyy"  v-model="newBirthdate" required>
                          </div>
                        </div>
                        <div class="col-md-2 text-md-right text-left">
                            <span class="text-primary" style="cursor:pointer" @click="toggleBirthdate">{{$t('settings.cancel')}}</span>
                        </div>
                        <div class="col-md-2 text-md-right text-left">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>
                            <span class="text-primary" style="cursor:pointer" @click="saveBirthdate">{{$t('settings.save')}}</span>
                        </div>
                        <label v-if="birthdateMessage">{{birthdateMessage}}</label>
                    </div>
                    <div v-if="!changeLanguage" class="row align-items-center justify-content-between">
                        <div class="col-md-8">
                            <span class="text-light font-size-13">{{$t('settings.personal-language')}}</span>
                            <p class="mb-0">{{currentLanguage}}</p>
                        </div>
                        <div class="col-md-4 text-md-right text-left">
                            <span class="text-primary" style="cursor:pointer" @click="toggleLanguage">{{$t('settings.change')}}</span>
                        </div>
                    </div>
                    <div v-else class="row align-items-center justify-content-between">
                        <div class="col-md-8">
                            <span class="text-light font-size-13">{{$t('settings.personal-language')}}</span>
                            <v-select :options="languageList" :reduce="languageName => languageName.languageCode" v-model="newLanguage" label="languageName" transition=""></v-select>
                        </div>
                        <div class="col-md-2 text-md-right text-left">
                            <span class="text-primary" style="cursor:pointer" @click="toggleLanguage">{{$t('settings.cancel')}}</span>
                        </div>
                        <div class="col-md-2 text-md-right text-left">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>
                            <span class="text-primary" style="cursor:pointer" @click="saveLanguage">{{$t('settings.save')}}</span>
                        </div>
                        <label v-if="languageMessage">{{languageMessage}}</label>
                    </div>
                    <h5 class="mb-3 mt-4 pb-3 a-border">{{$t('settings.billing-details')}}</h5>
                    <div class="row justify-content-between mb-3">
                        <div class="col-md-12 r-mb-15">
                            <p>{{$t('settings.billing-status')}}: {{currentPlanStatus}}</p>
                            <p>{{$t('settings.billing-valid-until')}}: {{currentPlanExpiration}}</p>
                            <a v-if="!hasUnpaidInvoice" @click="loadSpinner" href="#" class="btn btn-hover">
                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loadingUnpaid" style="margin-right:8px"></span>
                              {{$t('settings.billing-button')}}
                            </a>
                            <div v-else class="row mb-3" style="color:white">
                              <div class="col-md-12">
                                <div class="row">
                                  <div class="col-md-6 mb-3">
                                    <label style="color:white"> {{$t('settings.plan-plan')}}: {{invoicePlan}}</label>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                    <label style="color:white"> {{$t('settings.plan-issued')}}: {{invoiceIssueDate}}</label>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-6 mb-3">
                                    <label style="color:white"> {{$t('settings.plan-value')}}: {{invoiceAmount}}</label>
                                  </div>
                                  <div class="col-md-6 mb-3">
                                    <label style="color:white"> {{$t('settings.plan-hash')}}: {{gatewayCode}}</label>
                                  </div>
                                </div>
                                <div class="row">
                                  <div class="col-md-12 mb-3">
                                    <label style="color:white"> {{$t('settings.plan-qrcode')}}</label>
                                     <qrcode-vue :value="gatewayCode" :size="300" level="H" />
                                  </div>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                    <h5 class="mb-3 mt-4 pb-3 a-border">{{$t('settings.plan-details')}}</h5>
                    <div class="row justify-content-between mb-3">
                        <div class="col-md-8">
                            <p>{{currentPlan}}
                            </p>
                        </div>
                        <div class="col-md-4 text-md-right text-left">
                            <router-link :to="`/${$i18n.locale}/pricing`" class="text-primary">{{$t('settings.plan-change')}}</router-link>
                        </div>
                    </div>
                    <!-- <h5 class="mb-3 pb-3 mt-4 a-border">Setting</h5>
                    <div class="row">
                        <div class="col-12 setting">
                            <a href="#" class="text-body d-block mb-1">Recent device streaming activity</a>
                            <a href="#" class="text-body d-block mb-1">Sign out of all devices </a>
                            <a href="#" class="text-body d-block">Download your person information</a>
                        </div>
                    </div> -->
                </div>
            </b-col>
        </b-row>
    </b-container>
</section>
</template>
<script>
import auth from '../../services/auth'
import vSelect from 'vue-select'
import userSettings from '../../services/userSettings'
import { core } from '../../config/pluginInit'
import Inputmask from 'inputmask'
import QrcodeVue from 'qrcode.vue'

export default {
  name: 'Settings',
  components: {
    QrcodeVue,
    vSelect
  },
  async beforeMount () {
    const response = await userSettings.getUserDetails()
    if (response.result === 'success') {
      this.name = response.account.name
      this.newName = response.account.firstName
      this.newLastName = response.account.lastName
      this.avatar = response.account.avatar
      this.email = response.account.email
      this.birthdate = response.account.birthdate
      this.currentLanguage = localStorage.getItem('lang')
      this.currentPlanUID = response.account.currentPlanUID
      this.currentPlan = response.account.currentPlan
      this.currentPlanStatus = response.account.currentPlanStatus
      this.currentPlanExpiration = response.account.currentPlanExpiration
      this.hasUnpaidInvoice = response.account.hasUnpaidInvoice
      if (response.account.invoice) {
        this.invoicePlan = response.account.invoice.invoicePlan
        this.invoiceAmount = response.account.invoice.invoiceAmount
        this.invoiceIssueDate = response.account.invoice.invoiceIssueDate
        this.gatewayCode = response.account.invoice.gatewayCode
        this.Invoice = response.account.Invoice
      }
    }

    const res = await auth.getListLanguages()
    if (res.result === 'success') {
      this.languageList = res.languages
      this.newLanguage = res.languages.find(el => el.languageName === this.currentLanguage).languageCode
    }
    const resU = await userSettings.GetProfileInfo()
    if (resU.result === 'success') {
      this.photoUrl = resU.url
    }
  },
  mounted () {
    core.index()
    const selector = document.getElementById('bd-input')
    if (selector) {
      Inputmask('99/99/9999').mask(selector)
    }
  },
  updated () {
    const selector = document.getElementById('bd-input')
    if (selector) {
      Inputmask('99/99/9999').mask(selector)
    }
  },
  data () {
    return {
      name: '',
      avatar: '',
      email: '',
      birthdate: '',
      currentLanguage: '',
      currentPlanUID: '',
      currentPlan: '',
      currentPlanStatus: '',
      currentPlanExpiration: '',
      hasUnpaidInvoice: undefined,
      invoicePlan: '',
      invoiceAmount: '',
      invoiceIssueDate: '',
      gatewayCode: '',
      Invoice: undefined,
      changeName: false,
      changePassword: false,
      changeBirthdate: false,
      changeLanguage: false,
      newName: '',
      newLastName: '',
      newPassword: '',
      newConfirmPassword: '',
      newBirthdate: '',
      newLanguage: '',
      currentPassword: '',
      nameMessage: '',
      passwordMessage: '',
      birthdateMessage: '',
      languageMessage: '',
      languageList: [],
      loading: false,
      loadingUnpaid: false,
      photoUrl: ''
    }
  },
  methods: {
    toggleName () {
      this.changeName = !this.changeName
    },
    togglePassword () {
      this.changePassword = !this.changePassword
    },
    toggleBirthdate () {
      this.changeBirthdate = !this.changeBirthdate
    },
    toggleLanguage () {
      this.changeLanguage = !this.changeLanguage
    },
    async saveName () {
      this.loading = true
      if (this.newName.length > 0) {
        const response = await userSettings.changeDetails(this.newName, this.newLastName)
        if (response.result === 'success') {
          this.changeName = false
          const res = await userSettings.getUserDetails()
          if (res.result === 'success') {
            this.name = res.account.name
            this.avatar = res.account.avatar
            this.email = res.account.email
            this.birthdate = res.account.birthdate
            this.currentLanguage = res.account.currentLanguage
            this.currentPlanUID = res.account.currentPlanUID
            this.currentPlan = res.account.currentPlan
            this.currentPlanStatus = res.account.currentPlanStatus
            this.currentPlanExpiration = res.account.currentPlanExpiration
            this.hasUnpaidInvoice = res.account.hasUnpaidInvoice
            if (res.account.invoice) {
              this.invoicePlan = res.account.invoice.invoicePlan
              this.invoiceAmount = res.account.invoice.invoiceAmount
              this.invoiceIssueDate = res.account.invoice.invoiceIssueDate
              this.gatewayCode = res.account.invoice.gatewayCode
              this.Invoice = res.account.Invoice
            }
          }
          this.loading = false
        } else {
          this.loading = false
          this.nameMessage = 'Erro'
        }
      } else {
        this.loading = false
        this.nameMessage = 'Nome inválido'
      }
      this.loading = false
    },
    async savePassword () {
      this.loading = true
      if (this.newPassword === this.newConfirmPassword) {
        const response = await userSettings.changePassword(this.currentPassword, this.newPassword)
        if (response.result === 'success') {
          this.changePassword = false
          const res = await userSettings.getUserDetails()
          if (res.result === 'success') {
            this.name = res.account.name
            this.avatar = res.account.avatar
            this.email = res.account.email
            this.birthdate = res.account.birthdate
            this.currentLanguage = res.account.currentLanguage
            this.currentPlanUID = res.account.currentPlanUID
            this.currentPlan = res.account.currentPlan
            this.currentPlanStatus = res.account.currentPlanStatus
            this.currentPlanExpiration = res.account.currentPlanExpiration
            this.hasUnpaidInvoice = res.account.hasUnpaidInvoice
            if (res.account.invoice) {
              this.invoicePlan = res.account.invoice.invoicePlan
              this.invoiceAmount = res.account.invoice.invoiceAmount
              this.invoiceIssueDate = res.account.invoice.invoiceIssueDate
              this.gatewayCode = res.account.invoice.gatewayCode
              this.Invoice = res.account.Invoice
            }
          }
          this.loading = false
        } else {
          this.loading = false
          this.passwordMessage = 'Erro'
        }
      } else {
        this.loading = false
        this.passwordMessage = 'senhas não coincidem'
      }
      this.loading = false
    },
    async saveBirthdate () {
      this.loading = true
      if (this.newBirthdate.length > 0) {
        const response = await userSettings.changeDetails(undefined, undefined, this.newBirthdate)
        if (response.result === 'success') {
          this.changeBirthdate = false
          const res = await userSettings.getUserDetails()
          if (res.result === 'success') {
            this.name = res.account.name
            this.avatar = res.account.avatar
            this.email = res.account.email
            this.birthdate = res.account.birthdate
            this.currentLanguage = res.account.currentLanguage
            this.currentPlanUID = res.account.currentPlanUID
            this.currentPlan = res.account.currentPlan
            this.currentPlanStatus = res.account.currentPlanStatus
            this.currentPlanExpiration = res.account.currentPlanExpiration
            this.hasUnpaidInvoice = res.account.hasUnpaidInvoice
            if (res.account.invoice) {
              this.invoicePlan = res.account.invoice.invoicePlan
              this.invoiceAmount = res.account.invoice.invoiceAmount
              this.invoiceIssueDate = res.account.invoice.invoiceIssueDate
              this.gatewayCode = res.account.invoice.gatewayCode
              this.Invoice = res.account.Invoice
            }
          }
          this.loading = false
        } else {
          this.loading = false
          this.birthdateMessage = 'Erro'
        }
      } else {
        this.loading = false
        this.birthdateMessage = 'Data inválida'
      }
      this.loading = false
    },
    async saveLanguage () {
      this.loading = true
      if (this.newLanguage.length > 0) {
        localStorage.setItem('lang', this.newLanguage)
        const locale = this.newLanguage.substring(0, 2)
        this.$i18n.locale = locale
        this.$router.push('/')
      } else {
        this.loading = false
        this.languageMessage = 'Linguagem inválida'
      }
      this.loading = false
    },
    loadSpinner () {
      this.loadingUnpaid = !this.loadingUnpaid
    }
  }
}
</script>
<style scoped>
label {
  color: white
}
</style>
