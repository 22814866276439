<template>
  <div class="container pt-5">
    <div class="row no-gutters height-self-center">
      <div class="col-sm-12 text-center align-self-center">
        <div class="iq-error position-relative" style="background-color:black;border-radius:16px">
          <h2 class="mb-0">This site is under maintenance.</h2>
          <p>Please come back later.</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'

export default {
  name: 'Maintenance',
  mounted () {
    core.index()
  }
}
</script>
