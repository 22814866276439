<template>
  <div>
    <News  v-if="news.length > 0"  :news="news" @addFavorite="addFavorite" @removeFavorite="removeFavorite" @addMyList="addMyList" @removeMyList="removeMyList" />
    <Lastest v-if="lastest.length > 0"  style="margin-top:40px" :lastest="lastest" @addFavorite="addFavorite" @removeFavorite="removeFavorite" @addMyList="addMyList" @removeMyList="removeMyList" />
    <Advertisements style="margin-top:40px" v-if="advertisement" :detail="advertisement" @addMyList="addMyList" @removeMyList="removeMyList" />
    <Thrillers :trending="trending" @addFavorite="addFavorite" @removeFavorite="removeFavorite" @addMyList="addMyList" @removeMyList="removeMyList" />
    <Trending v-if="series.length > 0"  key:="currentWidth"  :currentWidth="currentWidth"  :series="series" @addFavorite="addFavorite" @removeFavorite="removeFavorite" @addMyList="addMyList" @removeMyList="removeMyList" />
    <Suggested v-if="suggested.length > 0"  :suggested="suggested" @addFavorite="addFavorite" @removeFavorite="removeFavorite" @addMyList="addMyList" @removeMyList="removeMyList" />
  </div>
</template>
<script>
import { core } from '../../config/pluginInit'
import Lastest from '../../views/FrontendPages/Components/Lastest/Lastest'
import Advertisements from './Advertisements/Advertisements'
import Suggested from '../../views/FrontendPages/Components/Suggested/Suggestion'
import Thrillers from '../../views/FrontendPages/Components/TvThrillers/Thrillers'
import Trending from '../../views/FrontendPages/Components/Trending/Trending'
import News from '../../views/FrontendPages/Components/News/News'
import videoList from '../../services/videoList'
export default {
  name: 'MainPage',
  components: {
    Lastest,
    Suggested,
    Thrillers,
    Advertisements,
    Trending,
    News
  },
  props: ['highlights', 'currentWidth'],
  async beforeMount () {
    const calls = [videoList.getLatestList('tutorial'), videoList.getSuggestedList('tutorial'), videoList.getTopVideosList('tutorial'), videoList.getAdvertisements('tutorial'), videoList.getLatestNews(), videoList.getAllSeries('tutorial')]
    const responses = await Promise.all(calls)
    if (responses[0].result === 'success') {
      this.lastest = responses[0].video_list
    }
    if (responses[1].result === 'success') {
      this.suggested = responses[1].video_list
    }
    if (responses[2].result === 'success') {
      this.trending = responses[2].video_list
    }
    if (responses[3].result === 'success') {
      this.advertisement = responses[3].advResponse
    }
    if (responses[4].result === 'success') {
      this.news = responses[4].video_list
    }
    if (responses[5].result === 'success') {
      this.series = responses[5].series
    }
  },
  async mounted () {
    core.index()
  },
  data: () => {
    return {
      lastest: [],
      suggested: [],
      trending: [],
      news: [],
      series: [],
      advertisement: undefined
    }
  },
  methods: {
    async addFavorite (uid) {
      const res = await videoList.postAddFavorite(uid)
      if (res.result === 'success') {
        // toggle no status do vídeo
        this.lastest = this.lastest.map(h => { if (h.uid === uid) { return { ...h, isFavorite: true } } else { return h } })
        this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: true } } else { return h } })
        this.trending = this.trending.map(h => { if (h.uid === uid) { return { ...h, isFavorite: true } } else { return h } })
        this.news = this.news.map(h => { if (h.uid === uid) { return { ...h, isFavorite: true } } else { return h } })
        this.$emit('addFavorite', uid)
      }
    },
    async removeFavorite (uid) {
      const res = await videoList.postRemoveFavorite(uid)
      if (res.result === 'success') {
        // toggle no status do vídeo
        this.lastest = this.lastest.map(h => { if (h.uid === uid) { return { ...h, isFavorite: false } } else { return h } })
        this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isFavorite: false } } else { return h } })
        this.trending = this.trending.map(h => { if (h.uid === uid) { return { ...h, isFavorite: false } } else { return h } })
        this.news = this.news.map(h => { if (h.uid === uid) { return { ...h, isFavorite: false } } else { return h } })
        this.$emit('removeFavorite', uid)
      }
    },
    async addMyList (uid) {
      const res = await videoList.postAddMyList(uid)
      if (res.result === 'success') {
        // toggle no status do vídeo
        this.lastest = this.lastest.map(h => { if (h.uid === uid) { return { ...h, isMyList: true } } else { return h } })
        this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isMyList: true } } else { return h } })
        this.trending = this.trending.map(h => { if (h.uid === uid) { return { ...h, isMyList: true } } else { return h } })
        this.$emit('addMyList', uid)
      }
    },
    async removeMyList (uid) {
      const res = await videoList.postRemoveMyList(uid)
      if (res.result === 'success') {
        // toggle no status do vídeo
        this.lastest = this.lastest.map(h => { if (h.uid === uid) { return { ...h, isMyList: false } } else { return h } })
        this.suggested = this.suggested.map(h => { if (h.uid === uid) { return { ...h, isMyList: false } } else { return h } })
        this.trending = this.trending.map(h => { if (h.uid === uid) { return { ...h, isMyList: false } } else { return h } })
        this.$emit('removeMyList', uid)
      }
    }
  }
}
</script>
