<template>
    <router-view :key="this.$router.currentRoute.path" ></router-view>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
</style>
