<template>
 <section id="iq-suggestede" class="s-margin">
   <b-container fluid>
      <b-row>
         <b-col sm="12" class="overflow-hidden">
            <div class="iq-main-header d-flex align-items-center justify-content-between">
               <h4 class="main-title" v-b-tooltip.hover title="">
                 {{$t('news.title')}}
               </h4>
               <router-link :to="{ path: `/${$i18n.locale}/news` }" class="iq-view-all">{{$t('general.view-all')}}</router-link>
            </div>
            <div class="suggestede-contens">
               <Slick class="list-inline favorites-slider row p-0 mb-0" style="display:block" ref="dSlick" :option="getSliderOption">
                  <li class="slide-item" v-for="(item,index) in videoListData" :key="index" @click="watchVideoSetUrl(item.uid)" style="cursor:pointer">
                        <div class="block-images position-relative">
                           <div class="img-box">
                              <img :src="item.image" class="img-fluid" alt="">
                           </div>
                           <div class="block-description" style="margin-top:20px;margin-bottom:20px;">
                              <p v-if="item.contentType === 'VideoSerie'" style="margin-bottom:0px;margin-top:auto;font-size:12px;"> <router-link :to="watchVideo(item.uid)">{{(item.contentType === 'VideoSerie'? substringSeason(item.serieTitle) + ' - ' + $t('general.season-abbr') + (item.seasonNumber ? item.seasonNumber: '') + ' - ' + $t('general.episode-abbr') + (item.episodeNumber ? item.episodeNumber: ''): '')}} </router-link></p>
                              <p :style="'margin-bottom:0px;' + (item.contentType !== 'VideoSerie'?'margin-top:auto;':'')"> <router-link style="fontWeight:bold;font-size:16px;" :to="watchVideo(item.uid)">{{substringTitle(item.title.toUpperCase())}} </router-link></p>
                              <div style="display:flex;gap:20px;">
                                <div class="hover-buttons">
                                    <span @click="watchVideoSetUrl(item.uid)" class="btn btn-hover"><i class="fa fa-play mr-1" aria-hidden="true"></i>
                                    {{$t('general.play-now')}}</span>
                                </div>
                                <div class="movie-time d-flex align-items-center my-2">
                                    <span class="text-white" v-if="!(item.contentType === 'VideoSerie' || item.serieType === 'Serie')">{{timeToString(item.time)}}</span><span class="text-white" v-else >{{item.seasonNumber}} {{$t('general.seasons')}}</span>
                                </div>
                              </div>
                           </div>
                           <div class="block-social-info">
                              <ul class="list-inline p-0 m-0 music-play-lists">
                                  <!-- <li class="share">
                                    <span><i class="ri-share-fill"></i></span>
                                    <div class="share-box">
                                        <div class="d-flex align-items-center">
                                          <a href="https://www.facebook.com/sharer?u=https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i class="ri-facebook-fill"></i></a>
                                          <a href="https://twitter.com/intent/tweet?text=Currentlyreading" target="_blank" rel="noopener noreferrer" class="share-ico" tabindex="0"><i class="ri-twitter-fill"></i></a>
                                          <a href="#" data-link="https://iqonic.design/wp-themes/streamit_wp/movie/shadow/" class="share-ico iq-copy-link" tabindex="0"><i class="ri-links-fill"></i></a>
                                        </div>
                                    </div>
                                  </li> -->
                                 <li style="display:none;" @click="toggleFavorite(item.isFavorite, item.uid, $event)"><span><i v-if="!item.isFavorite" class="ri-heart-line"></i><i v-else class="ri-heart-fill"></i></span></li>
                                <li style="display:none;" @click="toggleMyList(item.isMyList, item.uid, $event)"><span :class="!item.isMyList?'':'fill-background'"><i v-if="!item.isMyList" class="ri-add-line"></i><i v-else class="ri-add-line fill-background"></i></span></li>
                              </ul>
                           </div>
                        </div>

                  </li>
               </Slick>
            </div>
         </b-col>
      </b-row>
   </b-container>
</section>
</template>
<script>

export default {
  name: 'News',
  components: {
  },
  props: ['news'],
  mounted () {
  },
  data () {
    return {
      suggestionSliderOption: {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 300,
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        prevArrow: '<div class="slick-prev slick-arrow"><i class="fa fa-chevron-left"></i></div>',
        nextArrow: '<div class="slick-next slick-arrow"><i class="fa fa-chevron-right"></i></div>',
        responsive: [
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      }
    }
  },
  methods: {
    next () {
      this.$refs.dSlick.next()
    },
    prev () {
      this.$refs.dSlick.prev()
    },
    toggleFavorite (isFavorite, uid, e) {
      e.stopPropagation()
      if (isFavorite) {
        this.$emit('removeFavorite', uid)
      } else {
        this.$emit('addFavorite', uid)
      }
    },
    toggleMyList (isMyList, uid, e) {
      e.stopPropagation()
      if (isMyList) {
        this.$emit('removeMyList', uid)
      } else {
        this.$emit('addMyList', uid)
      }
    },
    watchVideoSetUrl (id) {
      this.$router.push(`/${this.$i18n.locale}/watch?v=` + id)
      return ''
    },
    watchVideo (id) {
      return (`/${this.$i18n.locale}/watch?v=` + id)
    },
    substringSeason (title) {
      if (title && title.length > 30) {
        return title.substring(0, 30) + '...'
      } else {
        return title
      }
    },
    substringTitle (title) {
      if (title && title.length > 60) {
        return title.substring(0, 60) + '...'
      } else {
        return title
      }
    },
    timeToString (segs) {
      if (segs <= 59) {
        return `${segs} seg`
      }
      return `${(segs / 60).toFixed(0)} min ${segs % 60 !== 0 ? (segs % 60).toString() + ' segs' : ''}`
    }
  },
  computed: {
    videoListData () {
      return this.news.map(l => { return { ...l, image: l.thumbCoverImage, time: l.duration } })
    },
    getSliderOption () {
      return this.suggestionSliderOption
    }
  },
  watch: {
    window (to, from) {

    }
  }
}
</script>

<style scoped>
.img-fluid {
width: 100%;
}
</style>
