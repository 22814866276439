<template>
    <div class="container">
        <div class="row justify-content-center align-items-center height-self-center">
            <div class="col-lg-5 col-md-12 align-self-center">
              <div class="sign-user_card ">
                  <div class="sign-in-page-data">
                    <div class="sign-in-from w-100 m-auto">
                        <h3 class="mb-3">Reset Password</h3>
                        <div class="alert alert-danger" v-if="message" role="alert">
                          <span >{{ message }}</span>
                        </div>
                        <div class="alert alert-primary" v-if="messageSuccess" role="alert">
                          <span >{{ messageSuccess }}</span>
                        </div>
                        <p class="text-body textStyle">Enter a new password.</p>
                        <form id="recover" class="mt-4">
                          <div class="form-group">
                            <label>Password</label>
                            <i :class="(showPass?'ri-eye-line ri-xl red':'ri-eye-line ri-xl ')" id="togglePassword" @click="togglePassword"></i>
                            <input  :type="showPass?'text':'password'"  class="form-control mb-0" id="new" v-model="newPassword" autocomplete="off" required>

                            <label style="margin-top:5px">Repeat</label>
                            <i :class="(showPass?'ri-eye-line ri-xl red':'ri-eye-line ri-xl ')" id="togglePassword" @click="togglePassword"></i>
                            <input  :type="showPass?'text':'password'"  class="form-control mb-0" id="repeat" v-model="repeatPassword" autocomplete="off" required>
                          </div>
                          <div class="sign-info">
                            <router-link to="/auth/login" class="btn btn-hover cancel-style grey" >Return to login</router-link>
                            <button v-if="showReset" class="btn btn-hover buttonStyle" @click="recaptcha">Reset</button>
                          </div>
                        </form>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>
</template>
<script>
import auth from '../../services/auth'
export default {
  name: 'Recover',
  data: () => {
    return {
      newPassword: '',
      repeatPassword: '',
      passwordToken: '',
      message: undefined,
      messageSuccess: undefined,
      showReset: true,
      showPass: false
    }
  },
  beforeMount () {
    const urlParams = new URLSearchParams(window.location.search)
    const myParam = urlParams.get('token')
    this.passwordToken = myParam
    const myEmail = urlParams.get('email')
    this.email = myEmail
  },
  methods: {
    togglePassword () {
      this.showPass = !this.showPass
    },
    async recaptcha (e) {
      // (optional) Wait until recaptcha has been loaded.
      e.preventDefault()

      if (this.newPassword !== this.repeatPassword) {
        this.message = 'Password does not match'
        this.messageSuccess = undefined
        return
      }
      // eslint-disable-next-line
      await grecaptcha.ready(() => {
        // eslint-disable-next-line
        grecaptcha.execute('6LfMA64aAAAAAFByMyPhKQJMynWvuqyTnH_ro57I', { action: 'recover' }).then(async (token) => {
          // Do stuff with the received token.
          const response = await auth.resetPassword(this.email, this.newPassword, this.passwordToken, token)

          switch (response) {
            case 'success':
              this.messageSuccess = 'Success'
              this.message = undefined
              this.showReset = false
              break
            case 'password_error':
              this.message = 'Password must have at least 6 characters'
              this.messageSuccess = undefined
              break
            case 'reset_password_token_error':
              this.message = 'Invalid token'
              this.messageSuccess = undefined
              break
            case 'invalid_captcha':
              this.message = 'Invalid Captcha'
              this.messageSuccess = undefined
              break
            default:
              this.message = 'Unknown error. Please contact support.'
              this.messageSuccess = undefined
              break
          }
        })
      })
    }
  }
}
</script>

<style scoped>
  input{
    background-color: rgba(65,65,65,0.480)
  }
  .buttonStyle{
    background-color: #bf000a;
    color: honeydew;
  }
  .cancel-style{
    background-color: #cecece;
    border-color: #cecece;
    color: honeydew;
  }
  .cancel-style::before{
    background-color: #cecece;
    border-color: #cecece;
    color: honeydew;
  }
  .textStyle{
    color: honeydew !important;
  }
  label{
    color: honeydew !important;
  }
  .grey{
    background-color: #dddddd;
    color: black;
  }
  .ri-eye-line {
    cursor: pointer;
    position: absolute;
    z-index: 999;
    height:2em;
    right: 0;
    padding:15px;
    margin-top: 32px;
    margin-right:15px;
  }
  .red {
    color: #bf000a
  }
</style>
