import axios from './index'

export default {
  async getNotifications () {
    try {
      const res = await axios.get('/api/Accounts/GetNotification', createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async setNotifications (uid) {
    try {
      const res = await axios.post('/api/Accounts/SetNotificationRead', { uid }, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  }
}
const createConfig = () => {
  const accessToken = localStorage.getItem('token')
  return {
    headers: {
      Authorization: 'Bearer ' + accessToken
    }
  }
}
