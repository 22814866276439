import axios from './index'

export default {
  async getLatestList (category) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!category) {
        category = ''
      }
      const res = await axios.get(`/api/Videos/${lang}/LatestList/${category}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getSuggestedList (category) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!category) {
        category = ''
      }
      const res = await axios.get(`/api/Videos/${lang}/suggestedList/${category}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getSuggestedListByVideoUid (videoUid) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!videoUid) {
        videoUid = ''
      }
      const res = await axios.get(`/api/Videos/${lang}/SimilarVideos/${videoUid}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getTopVideosList (category) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!category) {
        category = ''
      }
      const res = await axios.get(`/api/Videos/${lang}/topVideosList/${category}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getAllSeries (category) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!category) {
        category = ''
      }
      const res = await axios.get(`/api/Videos/${lang}/ListAllSeries/${category}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getSpotlightList (category) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!category) {
        category = ''
      }
      const res = await axios.get(`/api/Videos/${lang}/SpotlightList/${category}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getVideoList (language, contentType, category, genre, catalog) {
    try {
      let language = localStorage.getItem('lang')
      if (!language) {
        language = 'en-US'
      }
      const res = await axios.post('/api/Videos/List', { category, genre, catalog, language, contentType }, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getAllFavorites () {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      const res = await axios.get(`/api/Videos/${lang}/GetAllFavorites`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getAllMyList () {
    try {
      const res = await axios.get('/api/Videos/GetAllMyList', createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getLatestNews () {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      const category = 'news'
      const res = await axios.get(`/api/Videos/${lang}/LatestList/${category}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async postAddMyList (uid) {
    try {
      if (!uid) {
        uid = ''
      }
      const res = await axios.post(`/api/Videos/AddMyList/${uid}`, {}, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async postRemoveMyList (uid) {
    try {
      if (!uid) {
        uid = ''
      }
      const res = await axios.delete(`/api/Videos/RemoveMyList/${uid}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getSearchedVideoList (searchString) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!searchString) {
        searchString = ''
      }
      const res = await axios.post(`/api/Videos/${lang}/Search/`, { searchString }, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getCategoriesToDisplay () {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      const res = await axios.get(`/api/Videos/${lang}/ListCategories`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getLanguagesToDisplay () {
    try {
      const res = await axios.get('/api/Videos/ListLanguages', createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async postAddFavorite (uid) {
    try {
      if (!uid) {
        uid = ''
      }
      const res = await axios.post(`/api/Videos/AddFavorite/${uid}`, {}, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async postRemoveFavorite (uid) {
    try {
      if (!uid) {
        uid = ''
      }
      const res = await axios.post(`/api/Videos/RemoveFavorite/${uid}`, {}, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getVideoToPlay (id) {
    try {
      if (!id) {
        id = ''
      }
      const res = await axios.get(`/api/Videos/GetVideoToPlay/${id}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getListSeason (id) {
    try {
      if (!id) {
        id = ''
      }
      const res = await axios.get(`/api/Videos/ListSeason/${id}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  },
  async getAdvertisements (uid) {
    try {
      let lang = localStorage.getItem('lang')
      if (!lang) {
        lang = 'en-US'
      }
      if (!uid) {
        uid = ''
      }
      const res = await axios.get(`/api/Advertisements/${lang}/GetAdv/${uid}`, createConfig())
      return res.data
    } catch (error) {
      return { result: 'error' }
    }
  }
}

const createConfig = () => {
  const accessToken = localStorage.getItem('token')
  return {
    headers: {
      Authorization: 'Bearer ' + accessToken
    }
  }
}
