<template>
      <main id="main" class="site-main">
        <PrivacyPolicyDetail/>
    </main>
</template>
<script>
import PrivacyPolicyDetail from './PrivacyPolicy/PrivacyPolicyDetail'
export default {
  name: 'PrivacyPolicy',
  components: {
    PrivacyPolicyDetail
  }
}
</script>
