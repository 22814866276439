<template>
 <main id="main" class="site-main">
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-sm-12">
            <div class="iq-accordion iq-accordion-square">
               <div class="iq-accordion-block iq-active 1">
                  <div class="iq-accordion-title">
                     <div class="iq-icon-right">
                        <i aria-hidden="true" class="fa fa-minus active"></i>
                        <i aria-hidden="true" class="fa fa-plus inactive"></i>
                     </div>
                     <h4 class="mb-0 accordion-title iq-heading-title">
                        {{$t('faq.question1')}}
                     </h4>
                  </div>
                  <div class="iq-accordion-details" style="display: block;">
                     <p class="iq-content-text"> {{$t('faq.answer1')}} </p>
                  </div>
               </div>
               <div class="iq-accordion-block 2">
                  <div class="iq-accordion-title">
                     <div class="iq-icon-right">
                        <i aria-hidden="true" class="fa fa-minus active"></i>
                        <i aria-hidden="true" class="fa fa-plus inactive"></i>
                     </div>
                     <h4 class="mb-0 accordion-title iq-heading-title">
                        {{$t('faq.question2')}}
                     </h4>
                  </div>

                  <div class="iq-accordion-details" style="display: none;">
                     <p class="iq-content-text"> {{$t('faq.answer2')}}</p>
                  </div>
               </div>
               <div class="iq-accordion-block 3">
                  <div class="iq-accordion-title">
                     <div class="iq-icon-right">
                        <i aria-hidden="true" class="fa fa-minus active"></i>
                        <i aria-hidden="true" class="fa fa-plus inactive"></i></div>
                        <h4 class="mb-0 accordion-title iq-heading-title">
                        {{$t('faq.question3')}}
                        </h4>
                     </div>
                     <div class="iq-accordion-details" style="display: none;">
                        <p class="iq-content-text"> {{$t('faq.answer3')}}</p>
                     </div>
                  </div>
                  <div class="iq-accordion-block 4">
                     <div class="iq-accordion-title">
                        <div class="iq-icon-right">
                           <i aria-hidden="true" class="fa fa-minus active"></i>
                           <i aria-hidden="true" class="fa fa-plus inactive"></i>
                        </div>
                        <h4 class="mb-0 accordion-title iq-heading-title">
                        {{$t('faq.question4')}}
                        </h4>
                     </div>
                     <div class="iq-accordion-details" style="display: none;">
                     <p class="iq-content-text"> {{$t('faq.answer4')}}</p>
                  </div>
               </div>
               <div class="iq-accordion-block 5">
                  <div class="iq-accordion-title">
                     <div class="iq-icon-right">
                        <i aria-hidden="true" class="fa fa-minus active"></i>
                        <i aria-hidden="true" class="fa fa-plus inactive"></i></div>
                        <h4 class="mb-0 accordion-title iq-heading-title">
                        {{$t('faq.question5')}}
                        </h4>
                     </div>
                     <div class="iq-accordion-details" style="display: none;">
                        <p class="iq-content-text"> {{$t('faq.answer5')}}</p>
                     </div>
                  </div>
               </div>
         </div>
      </div>
   </div>
      </main>
</template>
<script>
import { core } from '../../../config/pluginInit'
export default {
  name: 'FaqList',
  mounted () {
    core.index()
  }
}
</script>
