<template>
    <div>
        <FaqList/>
    </div>
</template>
<script>
import FaqList from './Faq/FaqList'
export default {
  name: 'Faq',
  components: {
    FaqList
  }
}
</script>
