<template>
<section v-if="highlights && highlights.length > 0" id="home" class="iq-main-slider p-0">
  <Slick @init="navAnimateButton" @reInit="navAnimateButton" id="home-slider" class="slider m-0 p-0" :option="homeSliderOption">
    <div class="slide slick-bg s-bg-1" v-for="(data,index) in videoListData" :key="index" :style="'background: url('+data.src+')!important; padding: 100px 0 0px;width:100%; background-size: cover;background-position: center center; background-repeat: no-repeat; height: 100vh; position: relative; z-index: 1;'">
      <b-container fluid class="position-relative h-100 slick-bgm" :style="isCurrentWidthSmall?'background: url('+data.src+'); background-size: inherit;':''">
        <div class="slider-inner h-100">
            <b-row class="align-items-center  h-100">
              <b-col xl="6" lg="12" md="12">
                  <a href="#">
                    <div v-if="!isCurrentWidthSmall" class="channel-logo" data-animation-in="fadeInLeft" data-delay-in="0">
                        <h3 v-if="showText">{{showText}}</h3>
                        <img v-else src="../../../../assets/images/logo-full.png" class="c-logo" alt="iamgo">
                    </div>
                  </a>
                  <h1 class="slider-text big-title title text-uppercase" :style="isCurrentWidthSmall?'margin-top:20px;':''" data-animation-in="fadeInLeft"
                    data-delay-in="0">{{data.title}}</h1>
                  <div class="d-flex align-items-center" data-animation-in="fadeInUp" data-delay-in="0.1">
                     <div class="slider-ratting d-flex align-items-center mr-4 mt-2 mt-md-3">
                              <ul class="ratting-start p-0 m-0 list-inline text-primary d-flex align-items-center justify-content-left">
                                  <li>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                  </li>
                                  <li>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                  </li>
                                  <li>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                  </li>
                                  <li>
                                    <i class="fa fa-star" aria-hidden="true"></i>
                                  </li>
                                  <li>
                                    <i class="fa fa-star-half" aria-hidden="true"></i>
                                  </li>
                              </ul>
                                </div>
                    <span class="ml-3" v-if="data.contentType !== 'VideoSerie'">{{timeToString(data.duration)}}</span><span class="ml-3" v-else >{{data.seasonNumber}} {{$t('general.seasons')}}</span>
                  </div>
                  <div style="padding-top:20px; padding-bottom:20px;" data-animation-in="fadeInUp" data-delay-in="0.1" v-html="data.text"></div>
                    <div v-if="!isCurrentWidthSmall" class="trending-list" data-wp_object-in="fadeInUp" data-delay-in="0.1">
                            <div class="text-primary title starring">
                                {{$t('video.author')}}: <span class="text-body">{{data.author}}</span>
                            </div>
                            <div class="text-primary title genres">
                                {{$t('video.genres')}}: <span class="text-body">{{data.genre}}</span>
                            </div>
                            <div class="text-primary title tag">
                                {{$t('video.tags')}}: <span class="text-body">{{data.tags.join(', ')}}</span>
                            </div>
                        </div>

                  <div class="d-flex align-items-center r-mb-23" data-animation-in="fadeInUp" data-delay-in="0.1">
                    <div  :style="isCurrentWidthSmall?'margin-bottom:20px;':''" @click="watchVideo(data.uid)" class="btn btn-hover"><i class="fa fa-play mr-2"
                        aria-hidden="true"></i>{{$t('general.play-now')}}</div>
                  </div>
              </b-col>
            </b-row>
        </div>
      </b-container>
    </div>
  </Slick>
  <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44" width="44px" height="44px" id="circle"
        fill="none" stroke="currentColor">
        <circle r="20" cy="22" cx="22" id="test"></circle>
    </symbol>
  </svg>
</section>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  props: ['highlights'],
  data () {
    return {
      homeSliderOption: {
        autoplay: false,
        speed: 800,
        lazyLoad: 'progressive',
        arrows: true,
        dots: false,
        prevArrow: '<div class="slick-nav prev-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
        nextArrow: '<div class="slick-nav next-arrow"><i></i><svg><use xlink:href="#circle"></svg></div>',
        responsive: [
          {
            breakpoint: 992,
            settings: {
              dots: true,
              arrows: false
            }
          }
        ]
      },
      windowWidth: window.innerWidth
    }
  },
  methods: {
    navAnimateButton (event, slider) {
      const nav = document.getElementsByClassName('slick-nav')
      Array.from(nav, (elem) => {
        elem.addEventListener('click', (e) => {
          elem.classList.add('animate')
          setTimeout(() => {
            elem.classList.remove('animate')
          }, 1600)
        })
      })
    },
    watchVideo (id) {
      this.$router.push(`/${this.$i18n.locale}/watch?v=` + id)
    },
    substringTitle (title) {
      if (title && title.length > 60) {
        return title.substring(0, 60) + '...'
      } else {
        return title
      }
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    timeToString (segs) {
      if (segs <= 59) {
        return `${segs} seg`
      }
      return `${(segs / 60).toFixed(0)} min ${segs % 60 !== 0 ? (segs % 60).toString() + ' segs' : ''}`
    }
  },
  computed: {
    videoListData () {
      return this.highlights.map(l => { return { ...l, src: l.thumbCoverImage, text: l.shortDescription } })
    },
    showText () {
      switch ('/' + this.$router.currentRoute.path.split('/').pop()) {
        case '/promo':
          return 'PROMO'
        case '/tutorial':
          return 'TUTORIAL'
        case '/motivation':
          return 'MOTIVATION'
        default:
          return false
      }
    },
    isCurrentWidthSmall () {
      return this.windowWidth < 992
    }
  }
}
</script>
