<template>
  <!-- TOP Nav Bar -->
<header id="main-header">
   <div class="main-header">
      <b-container fluid>
         <b-row >
            <b-col sm="12">
               <nav class="navbar navbar-expand-lg navbar-light p-0">
                  <a href="javascript:void(0)" class="navbar-toggler c-toggler collapsed" @click="openSidebar">
                  <div class="navbar-toggler-icon" data-toggle="collapse">
                    <span class="navbar-menu-icon navbar-menu-icon--top"></span>
                    <span class="navbar-menu-icon navbar-menu-icon--middle"></span>
                    <span class="navbar-menu-icon navbar-menu-icon--bottom"></span>
                  </div>
                 </a>
                  <a class="navbar-brand" style="cursor:pointer;" @click="forceReload"> <img class="img-fluid logo" :src="logo" alt="iamgo" /></a>
                  <b-collapse id="navbarSupportedContent" is-nav :visible="sidebar">
                     <div class="menu-main-menu-container">
                        <ul id="top-menu" class="navbar-nav ml-auto">
                           <li v-for="(item,index) in items" :key="index" class="menu-item">
                              <router-link :to="item.link" :class="linkClass(item.link)">{{item.title}}</router-link>
                              <ul class="sub-menu" v-if="item.child">
                                 <li class="menu-item" v-for="(childitem,childindex) in item.children" :key="childindex">
                                    <router-link :to="childitem.link">{{childitem.title}}</router-link>
                                    <ul class="sub-menu" v-if="childitem.grandchild">
                                       <li class="menu-item" v-for="(grandchilditem,grandchildindex) in childitem.children" :key="grandchildindex">
                                          <router-link :to="grandchilditem.link">{{grandchilditem.title}}</router-link>
                                       </li>
                                    </ul>
                                 </li>
                              </ul>
                           </li>
                        </ul>
                     </div>
                  </b-collapse>
                  <div class="mobile-more-menu">
                     <div class="navbar-right position-relative">
                           <slot name="responsiveRight"/>
                     </div>
                  </div>
                  <div class="navbar-right menu-right">
                     <slot name="responsiveRight"/>
                  </div>
               </nav>
               <div class="nav-overlay"></div>
            </b-col>
         </b-row>
      </b-container>
   </div>
</header>
  <!-- TOP Nav Bar END -->
</template>
<script>
import { core, APPLOGONAME } from '../../../config/pluginInit'
export default {
  name: 'FrontendNav',
  props: {
    homeURL: { type: Object, default: () => ({ path: '/' }) },
    logo: { type: String, default: require('../../../assets/images/logo.png') },
    items: { type: Array },
    userprofile: { type: String }
  },
  data () {
    return {
      appName: APPLOGONAME,
      sidebar: false
    }
  },
  mounted () {
    core.index()
    document.addEventListener('click', this.closeSidebar, true)
  },
  destroyed () {
    document.removeEventListener('click', this.closeSidebar, true)
  },
  methods: {
    closeSidebar (e) {
      if (!e.target.classList.contains('navbar-toggler-icon')) {
        this.sidebar = false
        document.getElementsByTagName('body')[0].classList.remove('nav-open')
      }
    },
    openSidebar () {
      document.getElementsByTagName('body')[0].classList.add('nav-open')
      this.sidebar = true
    },
    forceReload () {
      this.$router.push(`/${this.$i18n.locale}/`)
    },
    linkClass (link) {
      if (this.$router.currentRoute.path.split('/').pop() === link.split('/').pop()) {
        return 'selected'
      }
      return ''
    }
  }
}
</script>
<style scoped>
.router-link-active.selected{
  color: #e50914!important;
}
</style>
