<template>
    <section class="m-profile">
        <b-container>
            <h4 class="main-title mb-4">{{$t('pricing.main-heading')}}</h4>
            <b-row class="justify-content-center">
                <b-col lg="12" >
                    <div class="sign-user_card">
                        <div class="table-responsive pricing pt-2">
                          <ul class="nav nav-tabs">
                            <li class="nav-item">
                              <a href="#monthly" class="nav-link active" role="tab" data-bs-toggle="tab">{{$t('pricing.tab-monthly')}}</a>
                            </li>
                            <li class="nav-item">
                              <a href="#quarterly" class="nav-link" role="tab" data-bs-toggle="tab">{{$t('pricing.tab-quarterly')}}</a>
                            </li>
                            <li class="nav-item">
                              <a href="#semiAnnual" class="nav-link" role="tab" data-bs-toggle="tab">{{$t('pricing.tab-semiannualy')}}</a>
                            </li>
                            <li class="nav-item">
                              <a href="#yearly" class="nav-link" role="tab" data-bs-toggle="tab">{{$t('pricing.tab-yearly')}}</a>
                            </li>
                          </ul>
                          <div class="tab-content">
                            <div class="tab-pane active" id="monthly">
                              <table id="my-table" class="table">
                                  <thead>
                                      <tr>
                                          <th class="text-center prc-wrap"></th>
                                          <th class="text-center prc-wrap" v-for="p in plans" :key="p.uid">
                                              <div class="prc-box">
                                                  <div :class="['h3', 'pt-4', 'text-white', p.isSuggested? 'active': '']">{{p.monthlyPrice}}<small> / {{$t('pricing.per-month')}}</small></div>
                                                  <span class="type">{{p.name}}</span>
                                              </div>
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="f in features" :key="f.order">
                                          <th class="text-center" scope="row">{{ f.name }}</th>
                                          <td :class="['text-center', 'child-cell', p.isSuggested? 'active': '']" v-for="p in plans" :key="p.name">
                                            <i :class="[p.planFeatures.map(f => f.id).includes(f.id)? 'ri-check-line': 'ri-close-line', p.isSuggested? 'ri-2x': 'i_close']"></i>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="text-center"><i class="ri-close-line i_close"></i></td>
                                          <td class="text-center" v-for="p in plans" :key="p.order">
                                              <a @click="redirect(p.uid,1)" href="#" class="btn btn-hover mt-3">
                                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>{{$t('pricing.button-purchase')}}</a>
                                          </td>
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                            <div class="tab-pane" role="tabpanel" id="quarterly">
                              <table id="my-table" class="table">
                                  <thead>
                                      <tr>
                                          <th class="text-center prc-wrap"></th>
                                          <th class="text-center prc-wrap" v-for="p in plans" :key="p.uid">
                                              <div class="prc-box">
                                                  <div :class="['h3', 'pt-4', 'text-white', p.isSuggested? 'active': '']">{{p.quarterlyPrice}}<small> / {{$t('pricing.per-quarter')}}</small></div>
                                                  <span class="type">{{p.name}}</span>
                                              </div>
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="f in features" :key="f.order">
                                          <th class="text-center" scope="row">{{ f.name }}</th>
                                          <td :class="['text-center', 'child-cell', p.isSuggested? 'active': '']" v-for="p in plans" :key="p.name">
                                            <i :class="[p.planFeatures.map(f => f.id).includes(f.id)? 'ri-check-line': 'ri-close-line', p.isSuggested? 'ri-2x': 'i_close']"></i>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="text-center"><i class="ri-close-line i_close"></i></td>
                                          <td class="text-center" v-for="p in plans" :key="p.order">
                                              <a @click="redirect(p.uid,3)" href="#" class="btn btn-hover mt-3">
                                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>{{$t('pricing.button-purchase')}}</a>
                                          </td>
                                      </tr>
                                  </tbody>
                                </table>
                              </div>
                            <div class="tab-pane" role="tabpanel" id="semiAnnual">
                              <table id="my-table" class="table">
                                  <thead>
                                      <tr>
                                          <th class="text-center prc-wrap"></th>
                                          <th class="text-center prc-wrap" v-for="p in plans" :key="p.uid">
                                              <div class="prc-box">
                                                  <div :class="['h3', 'pt-4', 'text-white', p.isSuggested? 'active': '']">{{p.semiAnnualPrice}}<small> / Por {{$t('pricing.per-semiannual')}}</small></div>
                                                  <span class="type">{{p.name}}</span>
                                              </div>
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="f in features" :key="f.order">
                                          <th class="text-center" scope="row">{{ f.name }}</th>
                                          <td :class="['text-center', 'child-cell', p.isSuggested? 'active': '']" v-for="p in plans" :key="p.name">
                                            <i :class="[p.planFeatures.map(f => f.id).includes(f.id)? 'ri-check-line': 'ri-close-line', p.isSuggested? 'ri-2x': 'i_close']"></i>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="text-center"><i class="ri-close-line i_close"></i></td>
                                          <td class="text-center" v-for="p in plans" :key="p.order">
                                              <a @click="redirect(p.uid,6)" href="#" class="btn btn-hover mt-3">
                                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>{{$t('pricing.button-purchase')}}</a>
                                          </td>
                                      </tr>
                                  </tbody>
                                </table>
                              </div>
                            <div class="tab-pane" role="tabpanel" id="yearly">
                              <table id="my-table" class="table">
                                  <thead>
                                      <tr>
                                          <th class="text-center prc-wrap"></th>
                                          <th class="text-center prc-wrap" v-for="p in plans" :key="p.uid">
                                              <div class="prc-box">
                                                  <div :class="['h3', 'pt-4', 'text-white', p.isSuggested? 'active': '']">{{p.annualPrice}}<small> / {{$t('pricing.per-year')}}</small></div>
                                                  <span class="type">{{p.name}}</span>
                                              </div>
                                          </th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="f in features" :key="f.order">
                                          <th class="text-center" scope="row">{{ f.name }}</th>
                                          <td :class="['text-center', 'child-cell', p.isSuggested? 'active': '']" v-for="p in plans" :key="p.name">
                                            <i :class="[p.planFeatures.map(f => f.id).includes(f.id)? 'ri-check-line': 'ri-close-line', p.isSuggested? 'ri-2x': 'i_close']"></i>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="text-center"><i class="ri-close-line i_close"></i></td>
                                          <td class="text-center" v-for="p in plans" :key="p.order">
                                              <a @click="redirect(p.uid,12)" href="#" class="btn btn-hover mt-3">
                                              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading" style="margin-right:8px"></span>{{$t('pricing.button-purchase')}}</a>
                                          </td>
                                      </tr>
                                  </tbody>
                                </table>
                              </div>
                          </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>
<script>
import payment from '../../services/payment'
import { core } from '../../config/pluginInit'
export default {
  name: 'PricingPlan',
  components: {
  },
  async beforeMount () {
    const response = await payment.GetPlans()
    if (response.result === 'success') {
      this.features = response.plans.features
      this.plans = response.plans.plans
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      features: [],
      plans: [],
      loading: false
    }
  },
  methods: {
    async redirect (uid, months) {
      this.loading = true
      const result = await payment.AddInvoice(uid, months)
      if (result === 'success') {
        this.$router.push('/checkout')
      }
      this.loading = false
    }
  }
}
</script>
