<template>
  <h1> Not Found</h1>
</template>

<script>

export default {
  name: 'NotFound',
  components: {
  },
  beforeMount () {
    this.$router.push('/')
  }
}
</script>

<style>
</style>
